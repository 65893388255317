import { logger } from '../utils/usefulFuncs';
import ExecutionState from '../classes/ExecutionState';
import { ExecutionTree } from '../classes/ExecutionTree';
import { NodeData } from '../classes/NodeUtils'; // function buildNodeExecutionOrder(node, nodes, edges) {

function slowClone(obj) {
  const str = JSON.stringify(obj);
  return JSON.parse(str);
}

export const executeFlowQuery = async (startNode, nodes, edges) => {
  console.log('---------------------------------');
  console.log('run', { startNode, nodes, edges });
  console.log('---------------------------------');

  const executionState = new ExecutionState();

  try {
    const state = {
      nodes,
      edges,
    };
    const mutableState = {
      nodes: slowClone(nodes),
      edges: slowClone(edges),
    };

    const startNodeData = new NodeData(startNode, mutableState.nodes, mutableState.edges);
    const { nextNodes } = startNodeData.data;

    if (!nextNodes) {
      const message = 'Please create and connect a series of nodes to execute.';
      startNode.data.actions.updateData({ isRunning: false, message });
      return;
    }

    startNode.data.actions.updateData({ isRunning: true, isComplete: false, didError: false, message: null });

    const allNodesInSeries = nodes;
    allNodesInSeries.forEach((node) => {
      node.data.actions.updateData({
        isQueryStarted: true,
        isComplete: null,
        answer: null,
        didError: false,
        message: null,
      });
    });

    nextNodes.forEach((nextNode) => {
      const executionTree = new ExecutionTree(nextNode, state, mutableState, executionState.addTree);
      executionState.addTree(executionTree);
      executionTree.run();
    });

    return new Promise((resolve) => {
      const onComplete = (resolve) => {
        return () => {
          startNode.data.actions.updateData({
            isRunning: false,
            isComplete: true,
            didError: false,
            message: null,
          });
          resolve(true);
        };
      };

      const onError = (resolve) => {
        return () => {
          logger('error while executing state');
          startNode.data.actions.updateData({ isRunning: false, didError: true });
          resolve(false);
        };
      };

      executionState.onComplete = onComplete(resolve);
      executionState.onError = onError(resolve);
    });
  } catch (e) {
    logger('error caught while running execution state', e);
    startNode.data.actions.updateData({ isRunning: false, didError: true });
    return false;
  }
};
