import React from 'react';
import { BASEURL } from '../../services/network';
import { logError, logger } from '../../utils/usefulFuncs';
import { sendApiRequest } from '../../services/api';
import * as Sentry from '@sentry/react';
import { getGlobalUserId } from '../../utils';

const runQualityCheck = (resultId) => {
  logger('running quality check', resultId);
  const form = {
    record_id: resultId,
  };
  const URL = `v1/genai_quality_check`;
  sendApiRequest('post', URL, form);
};

const logSentryError = (error, message) => {
  const scope = new Sentry.Scope();
  scope.setTag('error', error);
  scope.setTag('message', message);
  scope.setUser({ id: getGlobalUserId() });
  Sentry.captureException(new Error('Error streaming answer'), scope);
};

export function streamResult(resultId, handlers = {}, options) {
  return new Promise((resolve, reject) => {
    const { onOpen, onMessage, onRefiningStart, onError, onComplete } = handlers;

    const URL = `${BASEURL}/api/v1/genai_qa/stream_events?id=${resultId}`;
    const source = new EventSource(URL, {
      withCredentials: true,
    });
    source.addEventListener('open', function (event) {
      try {
        onOpen && onOpen();
      } catch (e) {
        logError('error occurred in stream', e);
        reject('error occurred in stream');
      }
    });
    source.addEventListener('error', function (error) {
      try {
        console.log('error occurred in stream', error, error?.currentTarget, error?.currentTarget?.message);
        logSentryError(error, error?.message);
        onError && onError(error);
        reject('error occurred in stream');
      } catch (e) {
        logError('error occurred in stream', e);
        reject('error occurred in stream');
      }
    });
    source.addEventListener('message', function (event) {
      try {
        const { data } = event;
        const streamData = JSON.parse(data);
        const { answer } = streamData;
        if (answer?.length > 0) {
          onMessage && onMessage(answer);
        }
      } catch (e) {
        logError('error occurred in stream', e);
        reject('error occurred in stream');
      }
    });
    source.addEventListener('refining', function (event) {
      try {
        onRefiningStart && onRefiningStart();
      } catch (e) {
        logError('error occurred in stream', e);
        reject('error occurred in stream');
      }
    });
    source.addEventListener('complete', function (event) {
      try {
        const { data } = event;
        const streamData = JSON.parse(data);
        const { response } = streamData;
        const { answer, context, context_with_all_headers: fullContext } = response;
        if (answer?.length > 0) {
          const result = {
            answer,
            context,
            fullContext,
          };
          onComplete(result, response);
          if (options?.enableQualityCheck) {
            runQualityCheck(resultId);
          }
          resolve(result);
        }
      } catch (e) {
        logError('error occurred in stream', e);
        reject('error occurred in stream');
      }
      source.close();
    });
  });
}

export const useStreamingResult = (resultId, options = {}) => {
  const [result, setResult] = React.useState('');

  const [refiningMessage, setRefiningMessage] = React.useState(false);
  const [fullContext, setFullContext] = React.useState(null);
  const [context, setContext] = React.useState(null);
  const [streamEnd, setStreamEnd] = React.useState(false);
  const [error, setError] = React.useState(null);

  const [reOpenStream, setReOpenStream] = React.useState(false);

  const getStreamingResponse = async (resultId, status) => {
    if (!resultId) {
      return null;
    }

    function onOpen() {
      if (!status.isCanceled) {
        setResult('Working...');
        setContext(null);
        setStreamEnd(false);
      }
    }

    function onMessage(answer) {
      if (!status.isCanceled) {
        if (answer?.length > 0) {
          if (!status.isCanceled) {
            setResult(answer);
            setError(null);
          }
        }
      }
    }

    function onRefiningStart() {
      if (!status.isCanceled) {
        setRefiningMessage(true);
      }
    }

    function onError(error) {
      if (!status.isCanceled) {
        console.log('error occurred in stream', error, error?.currentTarget, error?.currentTarget?.message);
        setError('Something went wrong.');
        logSentryError(error, error?.message);
        setTimeout(() => {
          setReOpenStream(true);
        }, 1000);
      }
    }

    function onComplete(result) {
      const { answer, context, fullContext } = result;
      if (!status.isCanceled) {
        setResult(answer);
        setRefiningMessage(false);
        setContext(context);
        setError(null);
        setFullContext(fullContext);
        setStreamEnd(true);
      }
    }

    const handlers = {
      onOpen,
      onMessage,
      onRefiningStart,
      onError,
      onComplete,
    };
    return streamResult(resultId, handlers, options);
  };

  React.useEffect(() => {
    if (resultId !== null) {
      let status = { isCanceled: false };
      const streamSource = getStreamingResponse(resultId, status);
      return () => {
        try {
          status.isCanceled = true;
          if (streamSource && streamSource.close) {
            streamSource.close();
          }
        } catch (e) {
          console.error(e);
        }
      };
    }
  }, [resultId]);

  React.useEffect(() => {
    if (resultId !== null && reOpenStream) {
      let status = { isCanceled: false };
      const streamSource = getStreamingResponse(resultId, status);
      return () => {
        try {
          status.isCanceled = true;
          if (streamSource && streamSource.close) {
            streamSource.close();
          }
        } catch (e) {
          console.error(e);
        }
      };
    }
  }, [reOpenStream]);

  return {
    result,
    isRefiningAnswer: refiningMessage,
    context,
    fullContext,
    error,
    streamEnd,
  };
};
