import React, { useState } from 'react';
import styled from 'styled-components';
import { Header } from '../components/navigation';
import { Body } from '../components/layout/Body';
import { useNavigate } from 'react-router';
import { ROUTES } from '../constants/routes';
import { coinFlip, includes, logger, scrollToTop, sleep } from '../utils/usefulFuncs';
import { AI_TOOLS, DOC_SELECT_MODES } from '../constants';
import { Body5, Container, H5, Page } from '../libs/nvstr-common-ui.es';
import BackArrowButton from '../components/buttons/BackArrowButton';
import { FlatButton } from '../components/buttons';
import { filterDocDuplicates } from '../reducers/docs';
import { NewDocumentSelectionModal } from '../containers/DigitalAnalyst/components/DocumentSelectionModal';
import { useCurrentUserToolsAvailable } from '../hooks/user/useCurrentUser';
import { SelectedDocuments } from '../containers/DigitalAnalyst/components/SelectedDocuments';
import { Loading } from '../components/UI/Loading';
import { FormState } from '../components/UI/FormState';

const PageWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

const Input = styled.textarea`
  border: 1px solid ${({ theme }) => theme.themeColors.border};

  font-size: 14px;
  line-height: 18px;
  color: ${({ theme }) => theme.themeColors.text};

  background-color: transparent;
  border-radius: 6px;
  width: 100%;
  padding: 8px;

  min-height: 120px;
`;
const SubmitButtonWrapper = styled.div`
  text-align: center;
  width: 100%;
`;

const tool = AI_TOOLS.CITATIONS_GENERATOR;

const text = 'This is a sentence answering your question.';
const extendedText = 'This is a sentence answering your question, extended for better viewing.';

const citationText = 'I found the answer to your question in this text.';
let counter = 0;

function getId() {
  return ++counter;
}

function generateMock() {
  const output = { lines: [], citations: [] };
  for (let i = 0; i < 10; i++) {
    const id = getId();
    const ids = [id];
    if (coinFlip()) {
      ids.push(getId());
    }
    if (coinFlip()) {
      ids.push(getId());
    }
    const line = {
      text: coinFlip() ? text : extendedText,
      ids: coinFlip() ? ids : [],
    };
    output.lines.push(line);
    const citation = {
      text: citationText,
      doc: { filename: 'Microsoft Corp. 8K 12-23-2022' },
      id,
    };
    output.citations.push(citation);
  }
  return output;
}

const mock = generateMock();

function selectDocSelectModes({ isCompanyTaggingEnabled }) {
  const modes = [DOC_SELECT_MODES.docType];
  if (isCompanyTaggingEnabled) {
    modes.push(DOC_SELECT_MODES.companyTag);
  }
  return modes;
}

function Tool({ setIsSubmittingToApi, setAnswer }) {
  const toolsAvailable = useCurrentUserToolsAvailable();
  const isCompanyTaggingEnabled = toolsAvailable.includes(AI_TOOLS.COMPANY_TAGGING);
  const [docSelectModes] = React.useState(
    selectDocSelectModes({
      isCompanyTaggingEnabled,
    })
  );

  const [value, setValue] = useState('');
  const [isSelectingDocs, setIsSelectingDocs] = React.useState(false);
  const [selectedDocs, setSelectedDocs] = React.useState([]);
  const [errorMessage, setErrorMessage] = useState(null);

  const handleValueChange = (e) => setValue(e.target.value);

  const onSelectedDocsChange = (t) => setSelectedDocs(t);

  const onDismiss = () => setIsSelectingDocs(false);

  const onSelectDocumentsClick = () => setIsSelectingDocs(true);

  const handleAddDocument = (v) => {
    if (Array.isArray(v)) {
      onSelectedDocsChange((prevState) => filterDocDuplicates([...prevState, ...v]));
    } else {
      onSelectedDocsChange((prevState) => filterDocDuplicates([...prevState, v]));
    }
  };

  const handleRemoveDocument = (filenameOrFilenames) => {
    if (Array.isArray(filenameOrFilenames)) {
      onSelectedDocsChange((prevState) => prevState.filter((d) => !includes(filenameOrFilenames, d.filename)));
    } else {
      onSelectedDocsChange((prevState) => prevState.filter((d) => d.filename !== filenameOrFilenames));
    }
  };

  const onGenerateCitations = async () => {
    if (value === '') {
      setErrorMessage('Must enter text to source');
      return;
    }
    if (selectedDocs.length === 0) {
      setErrorMessage('Must select source documents');
      return;
    }

    setErrorMessage(null);
    setIsSubmittingToApi(true);
    await sleep(3000);
    setAnswer(mock);
    setIsSubmittingToApi(false);
  };

  return (
    <Container>
      {isSelectingDocs && (
        <NewDocumentSelectionModal
          docSelectModes={docSelectModes}
          defaultActiveMode={docSelectModes[0]}
          selectedDocs={selectedDocs}
          handleAddDocument={handleAddDocument}
          handleRemoveDocument={handleRemoveDocument}
          onDismiss={onDismiss}
        />
      )}

      <Container top={0}>
        <Body5>Text:</Body5>

        <Container top={8}>
          <Input value={value} onChange={handleValueChange} />
        </Container>
      </Container>
      <Container top={24}>
        <Body5>Sources:</Body5>

        <Container top={8}>
          <SelectedDocuments
            onSelectDocumentsClick={onSelectDocumentsClick}
            selectedDocs={selectedDocs}
            onSelectedDocsChange={onSelectedDocsChange}
            showAddAllDocumentsAction={false}
          />
        </Container>
      </Container>

      <Container top={24}>
        <Container centerAll>
          <FormState error={errorMessage} />
        </Container>
        <SubmitButtonWrapper>
          <FlatButton fullWidth onClick={onGenerateCitations}>
            Generate Citations
          </FlatButton>
        </SubmitButtonWrapper>
      </Container>
    </Container>
  );
}

const CitationIdPositionWrapper = styled.div`
  position: relative;
  display: inline;
  padding-right: ${({ Value }) => (Value > 99 ? '16px' : Value > 9 ? '12px' : '8px')};
`;
const CitationIdWrapper = styled.div`
  position: absolute;
  top: 1px;
  right: ${({ Value }) => (Value > 99 ? '1px' : Value > 9 ? '3px' : '5px')};
  font-size: 8px;
  font-weight: bold;
  color: ${({ theme }) => theme.themeColors.text};
`;

const LineWrapper = styled.div`
  display: inline-block;
  position: relative;
`;

const CitationsLineIdWrapper = styled.div`
  display: inline-block;
  width: 30px;
`;

const Line = ({ data }) => {
  return (
    <LineWrapper>
      <Body5>{data.text}&nbsp;</Body5>
      {data.ids.map((id) => {
        const value = id;
        return (
          <CitationIdPositionWrapper Value={value}>
            <CitationIdWrapper Value={value}>{value}</CitationIdWrapper>
          </CitationIdPositionWrapper>
        );
      })}
    </LineWrapper>
  );
};

const CitationLineWrapper = styled.div`
  padding-bottom: 16px;
`;

const CitationLine = ({ data }) => {
  return (
    <CitationLineWrapper>
      <CitationsLineIdWrapper>
        <Body5>{data.id}</Body5>
      </CitationsLineIdWrapper>
      <Body5>{data.doc.filename}&nbsp;&nbsp;&nbsp;</Body5>
      <Body5>{data.text}</Body5>
    </CitationLineWrapper>
  );
};

const CitationsAnswer = ({ isLoading, answer }) => {
  if (isLoading) {
    return (
      <Container bottom={200}>
        <Loading />
      </Container>
    );
  }

  const { lines, citations } = answer;
  return (
    <Container top={8}>
      <Container bottom={8}>
        <Body5>Input:</Body5>
      </Container>
      {lines.map((line) => (
        <Line key={line.id} data={line} />
      ))}
      <Container top={24} bottom={8}>
        <Body5>Citations:</Body5>
      </Container>
      {citations.map((citation, i) => (
        <CitationLine key={i} data={citation} />
      ))}
    </Container>
  );
};

function CitationsGenerator() {
  const navigate = useNavigate();

  const [answer, setAnswer] = React.useState(null);
  const [isSubmittingToApi, setIsSubmittingToApi] = React.useState(false);

  const onGoBack = () => navigate(ROUTES.home);

  React.useEffect(() => {
    scrollToTop();
  }, []);

  React.useEffect(() => {
    logger('change', answer, isSubmittingToApi);
  }, [answer, isSubmittingToApi]);

  return (
    <PageWrapper>
      <Header />
      <Body withFooter>
        <Page width={'1400px'}>
          <BackArrowButton onClick={onGoBack} />
          <Container top={36}>
            <H5 bold>Generate Citations</H5>
            <Container top={2}>
              <Body5>Insert text and select source documents to generate citations.</Body5>
            </Container>
          </Container>
          <Container top={24}>
            <Tool setAnswer={setAnswer} setIsSubmittingToApi={setIsSubmittingToApi} />
          </Container>
        </Page>
        <div id={'question-answer'}></div>
        {answer || isSubmittingToApi ? (
          <Page width={'1400px'}>
            <CitationsAnswer answer={answer} isLoading={isSubmittingToApi} />
          </Page>
        ) : null}
      </Body>
    </PageWrapper>
  );
}

export default CitationsGenerator;
