import React from 'react';
import styled from 'styled-components';
import { Body5, Container, H5 } from '../../libs/nvstr-common-ui.es';
import { ResultOnly } from '../../containers/DigitalAnalyst/components/AnswerLine';
import { SkeletonButton } from '../buttons/SkeletonButton';
import { ROUTES } from '../../constants/routes';

const Wrapper = styled.div``;
const HeaderWrapper = styled.div`
  padding: 8px 0 8px 0;

  h5,
  p {
    padding: 0 0 0 0;
    margin: 0 0 0 0;
  }
`;
const KeyValueTable = styled.table`
  border: none;
  width: 100%;

  tr {
  }

  td {
    &:first-child {
      width: 160px;
    }

    vertical-align: top;
    border-bottom: 1px solid ${({ theme }) => theme.themeColors.border};
  }
`;
const FinTableWrapper = styled.div`
  width: 100%;
  overflow-x: auto;
  padding: 0 0 16px 0;

  table {
    td,
    th {
      border: none;
      background: none;
    }

    td {
      &:first-child {
        width: 140px;
        text-align: left;
      }

      text-align: right;
      min-width: 110px;
    }

    tr:nth-child(2),
    tr:nth-child(4) {
      td {
        font-style: italic;
        border-bottom: 1px solid ${({ theme }) => theme.themeColors.border};
      }
    }

    tr:nth-child(1),
    tr:nth-child(3),
    tr:nth-child(5) {
      td {
        font-weight: bold;
      }
    }

    th {
      &:first-child {
        text-align: left;
      }

      text-align: right;
      border-bottom: 1px solid ${({ theme }) => theme.themeColors.border};
    }
  }
`;

const SectionWrapper = styled.div`
  padding-top: 24px;

  width: 50%;
`;

const KeyValue = ({ keyName, templateKey, answers }) => {
  const answerValue = answers[templateKey] || 'Working...';
  return (
    <Container>
      <Body5 bold>{keyName}: </Body5>
      <ResultOnly result={answerValue} />
    </Container>
  );
};

const KeyValueRowGroup = ({ children }) => {
  return (
    <KeyValueTable>
      <tbody>{children}</tbody>
    </KeyValueTable>
  );
};
const KeyValueRow = ({ keyName, templateKey, answers }) => {
  const answerValue = answers[templateKey] || 'Working...';
  return (
    <tr>
      <td>
        <Container top={16}>
          <Body5 bold>{keyName}: </Body5>
        </Container>
      </td>
      <td>
        <ResultOnly result={answerValue} />
      </td>
    </tr>
  );
};

const TextAnswer = ({ answers, templateKey }) => {
  const answerValue = answers[templateKey] || 'Working...';

  return <ResultOnly result={answerValue} />;
};

const ParagraphValue = ({ answers, templateKey }) => {
  const answerValue = answers[templateKey] || 'Working...';
  return (
    <Container top={12}>
      <ResultOnly result={answerValue} />
    </Container>
  );
};

export const GrowthEquityMemo = ({ answers, selectedDocs }) => {
  if (!answers) {
    return null;
  }

  const onQueryDocumentsClick = () => {
    const url = ROUTES.digitalAnalyst + `?doc-ids=${selectedDocs.map((d) => d.id).join(',')}`;
    window.open(url, '_blank');
  };

  return (
    <Wrapper>
      <HeaderWrapper>
        <Container row verticallyCenter spaceBetween>
          <H5>
            <TextAnswer answers={answers} templateKey={'{{TEMPLATE_1}}'} />
          </H5>
          <Container left={8}>
            <SkeletonButton onClick={onQueryDocumentsClick}>Query Documents</SkeletonButton>
          </Container>
        </Container>
      </HeaderWrapper>

      <Container row>
        <SectionWrapper>
          <Container right={12}>
            <Container bottom={16}>
              <Body5 uppercase bold>
                Overview
              </Body5>
            </Container>

            <KeyValueRowGroup>
              <KeyValueRow keyName={'Deal Team'} answers={answers} templateKey={'{{TEMPLATE_14}}'} />
              <KeyValueRow keyName={'Sector / Industry'} answers={answers} templateKey={'{{TEMPLATE_15}}'} />
              <KeyValueRow keyName={'Location'} answers={answers} templateKey={'{{TEMPLATE_16}}'} />
              <KeyValueRow keyName={'Financing Type'} answers={answers} templateKey={'{{TEMPLATE_17}}'} />
              <KeyValueRow keyName={'Financing Size'} answers={answers} templateKey={'{{TEMPLATE_18}}'} />
              <KeyValueRow keyName={'Lead Source'} answers={answers} templateKey={'{{TEMPLATE_19}}'} />
              <KeyValueRow keyName={'Banked'} answers={answers} templateKey={'{{TEMPLATE_20}}'} />
            </KeyValueRowGroup>
          </Container>
        </SectionWrapper>

        <SectionWrapper>
          <Container left={12}>
            <Container bottom={6}>
              <Body5 uppercase bold>
                Deal Synopsis
              </Body5>
            </Container>
            <ParagraphValue answers={answers} templateKey={'{{TEMPLATE_2}}'} />
            <ParagraphValue answers={answers} templateKey={'{{TEMPLATE_3}}'} />
            <ParagraphValue answers={answers} templateKey={'{{TEMPLATE_4}}'} />
            <ParagraphValue answers={answers} templateKey={'{{TEMPLATE_5}}'} />
          </Container>
        </SectionWrapper>
      </Container>

      <Container row>
        <SectionWrapper>
          <Container right={12}>
            <Container bottom={16}>
              <Body5 uppercase bold>
                Summary Financials
              </Body5>
            </Container>
            <FinTableWrapper>
              <ParagraphValue answers={answers} templateKey={'{{TEMPLATE_39}}'} />
            </FinTableWrapper>
          </Container>
        </SectionWrapper>

        <SectionWrapper>
          <Container left={12}>
            <Container bottom={16}>
              <Body5 uppercase bold>
                Business Overview
              </Body5>
            </Container>
            <ParagraphValue answers={answers} templateKey={'{{TEMPLATE_6}}'} />
            <ParagraphValue answers={answers} templateKey={'{{TEMPLATE_7}}'} />
            <ParagraphValue answers={answers} templateKey={'{{TEMPLATE_8}}'} />
          </Container>
        </SectionWrapper>
      </Container>

      <Container row>
        <SectionWrapper>
          <Container right={12}>
            <Container bottom={16}>
              <Body5 uppercase bold>
                Overview of cap table
              </Body5>
            </Container>
            <KeyValueRowGroup>
              <KeyValueRow keyName={'Most Recent Round'} answers={answers} templateKey={'{{TEMPLATE_21}}'} />
              <KeyValueRow keyName={'Equity Raised to Date'} answers={answers} templateKey={'{{TEMPLATE_22}}'} />
              <KeyValueRow keyName={'Debt Outstanding'} answers={answers} templateKey={'{{TEMPLATE_23}}'} />
            </KeyValueRowGroup>
          </Container>
        </SectionWrapper>

        <SectionWrapper>
          <Container left={12}>
            <Container bottom={16}>
              <Body5 uppercase bold>
                Preliminary View, Key Areas of Diligence, & Next Steps
              </Body5>
            </Container>
            <ParagraphValue answers={answers} templateKey={'{{TEMPLATE_9}}'} />
            <ParagraphValue answers={answers} templateKey={'{{TEMPLATE_10}}'} />
            <ParagraphValue answers={answers} templateKey={'{{TEMPLATE_11}}'} />
            <ParagraphValue answers={answers} templateKey={'{{TEMPLATE_12}}'} />
          </Container>
        </SectionWrapper>
      </Container>

      <Container row>
        <Container top={24}>
          <Container bottom={16}>
            <Body5 uppercase bold>
              Revenue Retention Analysis
            </Body5>
          </Container>
          <ParagraphValue answers={answers} templateKey={'{{TEMPLATE_13}}'} />
        </Container>
      </Container>
    </Wrapper>
  );
};
