import styled from 'styled-components';

export const NodeContentWrapper = styled.div`
  padding: 8px;
  min-width: 400px;
  cursor: default;

  input,
  textarea {
    border: 1px solid ${({ theme }) => theme.themeColors.border};
    color: ${({ theme }) => theme.themeColors.text};
    background-color: transparent;
    border-radius: 6px;

    height: 220px;

    width: 100%;
    padding: 8px;

    font-size: 13px;

    resize: none;
  }
`;
