import React from 'react';
import { NodeHeader } from './components/NodeHeader';
import { NodeContentWrapper } from './styles';
import { SkeletonButton } from '../../../components/buttons/SkeletonButton';
import styled from 'styled-components';
import { Body5, Container } from '../../../libs/nvstr-common-ui.es';
import InputSourceHandle from './components/handles/InputSourceHandle';
import { NODE_HANDLE_IDS } from './index';
import { NodeDebugger } from './components/NodeDebugger';
import { NodeWrapper } from './components/NodeWrapper';

const ContentWrapper = styled.div`
  padding: 12px 0 16px 0;

  button {
    padding: 6px 12px;
  }
`;

export const DocListNode = (props) => {
  const { data, id } = props;

  const hc = () => {
    data.actions.onShowDocumentSelection();
  };

  const documentsSelected = data.selectedDocs;
  const name = 'Documents List';
  return (
    <NodeWrapper data-id={id} name={name} data={data}>
      <NodeHeader id={id} name={name} actions={data.actions} Left={8} Right={8} />
      <NodeContentWrapper>
        <ContentWrapper>
          <Container bottom={16} left={2}>
            <Body5 bold>{`${documentsSelected.length} Document${
              documentsSelected.length === 1 ? '' : 's'
            } Selected`}</Body5>
          </Container>
          <SkeletonButton onClick={hc}>Select Documents</SkeletonButton>
        </ContentWrapper>
      </NodeContentWrapper>

      <InputSourceHandle {...props} handleActionId={NODE_HANDLE_IDS.DocumentList} />

      <NodeDebugger id={id} />
    </NodeWrapper>
  );
};
