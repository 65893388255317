import styled from 'styled-components';
import React from 'react';
import { Loading } from '../../../../components/UI/Loading';
import { EXECUTION_NODE_TYPES } from '../index';
import { Container } from '../../../../libs/nvstr-common-ui.es';

export const Wrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.themeColors.border};
  border-radius: 5px;
  background: ${({ theme }) => theme.themeColors.componentNoOpacity};
`;

const NodeLoadingContentWrapper = styled.div`
  position: absolute;
  top: 25px;
  left: 1px;
  bottom: 1px;
  right: 1px;
  border-radius: 2px;

  background: ${({ theme }) => theme.themeColors.component};
`;

const enableQueryStartedMessage = (data, name) => {
  const { type } = data;
  const isExecutionNode = EXECUTION_NODE_TYPES.includes(type);
  if (isExecutionNode) {
    if (data.isWorking || data.isComplete || data.answer === 'Working...') {
      return false;
    }
    if (name === 'Start') {
      return false;
    }
    return true;
  } else {
    return false;
  }
};

export const NodeWrapper = ({ children, data, name }) => {
  const { isQueryStarted, id } = data;
  const showLoading = false && enableQueryStartedMessage(data, name) && isQueryStarted;
  if (showLoading) {
    return (
      <Wrapper>
        <NodeLoadingContentWrapper>
          <Container top={24}>
            <Loading noVerticalPadding message={'Awaiting previous node to finish'} />
          </Container>
        </NodeLoadingContentWrapper>
        {children}
      </Wrapper>
    );
  }
  return <Wrapper>{children}</Wrapper>;
};
