import { createGlobalStyle } from 'styled-components';
import { FONT } from '../../constants/app';

const GlobalStyles = createGlobalStyle`
    body {
        min-height: 100vh;
        background-color: ${({ theme }) => theme.themeColors.appBackground};
        color: ${({ theme }) => theme.themeColors.text};
        font-family: ${() => FONT.normal};
        box-sizing: border-box;
    }

    #root {
        background-color: ${({ theme }) => theme.themeColors.appBackground};
    }

    div {
        line-height: 1;
    }

    * {
        color: ${({ theme }) => theme.themeColors.text};
        box-sizing: border-box;
    }

    span,
    div,
    a,
    div,
    p {
        font-family: ${() => FONT.normal};
    }

    span,
    div,
    p {
        color: inherit;
    }

    a {
        color: ${({ theme }) => theme.themeColors.text};
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: ${({ theme }) => theme.themeColors.text};
        font-family: ${() => FONT.bold};
        font-weight: 600;
        margin: 0;
    }

    h1 {
        font-size: 48px;
        line-height: 54px;
        text-transform: uppercase;

        @media (max-width: 950px) {
            font-size: 36px;
            line-height: 44px;
        }

        @media (max-width: 550px) {
            font-size: 24px;
            line-height: 28px;
        }
    }

    h3 {
        font-size: 24px;
        line-height: 28px;
        text-transform: uppercase;

        @media (max-width: 550px) {
            font-size: 18px;
            line-height: 24px;
        }
    }

    .bold,
    b {
        color: inherit;
        font-family: ${() => FONT.bold};
        font-weight: 600;

        * {
            color: inherit;
            font-family: inherit;
            font-weight: inherit;
        }
    }

    .thin {
        font-family: ${() => FONT.thin};
        font-weight: 100;
    }

    textarea,
    select,
    input {
        font-family: ${() => FONT.normal};
        background: transparent;
        border: none;
        border-bottom: 1px solid ${({ theme }) => theme.themeColors.text};
        border-radius: 0;
        color-scheme: ${({ colorThemeName }) => (colorThemeName === 'dark-professional' ? 'dark' : 'light')};
    }

    /* Applies to both vertical and horizontal scrollbars */
    ::-webkit-scrollbar {
        //width: 12px;  /* Width for vertical scrollbar */
        //height: 12px; /* Height for horizontal scrollbar */
    }

    ::-webkit-scrollbar-track {
        background: transparent;
    !important; /* Dark background for the track */
    }

    ::-webkit-scrollbar-thumb {
        background-color: #555;
    !important; /* Darker thumb */
        border-radius: 6px;
    !important;
        border: 2px solid #333;
    !important; /* Creates a slight separation effect */
    }

    /* For Firefox */
    * {
        scrollbar-color: #555 transparent;
    !important; /* thumb color followed by track color */
        scrollbar-width: thin;
    !important; /* Options: auto, thin, or none */
    }
`;

export default function GlobalStyler() {
  return (
    <>
      <GlobalStyles />
    </>
  );
}
