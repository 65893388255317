import React from 'react';
import { Body1, Body5, Container, Page } from '../../../libs/nvstr-common-ui.es';
import { useNewCompanySnapshotStreamingResult } from '../../../hooks/features/useNewCompanySnapshotStreamingResult';
import styled from 'styled-components';
import { ResultOnly } from './AnswerLine';
import { SkeletonButton } from '../../../components/buttons/SkeletonButton';
import { ROUTES } from '../../../constants/routes';
import { AI_TOOLS } from '../../../constants';
import { useAdvancedSettings } from '../../../hooks/application/useAdvancedSettings';
import { useOrderedCitations } from '../../../hooks/features/useOrderedCitations';
import { Loading } from '../../../components/UI/Loading';

const RowAnswerWrapper = styled.div`
  //border-bottom: 1px solid ${({ theme }) => theme.themeColors.border};
  //display: grid;
  //grid-template-columns: 180px 1fr;
`;
const RowAnswerListWrapper = styled.div``;

const SectionsWrapper = styled.div``;
const SectionWrapper = styled.div`
  padding: 24px 0 0 0;
`;

const ReportWrapper = styled.div`
  padding: 16px 0 16px 8px;

  ul {
    padding: 0 0 0 36px;

    li {
      list-style-type: disc;
    }

    li li {
      list-style-type: circle;
    }

    li.extra-indent {
      margin-left: 36px;

      list-style-type: square !important;
    }
  }

  ul li {
    margin: 0 0 4px 0;
  }
`;

const RowAnswer = ({
  label,
  data,
  index,
  childRecordIds,
  citationAnswers,
  sourceDocs,
  docIds,
  level,
  onCitationClick,
}) => {
  const { model } = useAdvancedSettings();

  const [answer, setAnswer] = React.useState(null);
  const [isShowingCitations, setIsShowingCitations] = React.useState(false);

  React.useEffect(() => {
    const citation = citationAnswers ? citationAnswers[index] : null;
    if (citation && !isShowingCitations) {
      setAnswer(citation);
      setIsShowingCitations(true);
    } else {
      const nextAnswer = data ? data[index] : null;
      if (nextAnswer && nextAnswer !== answer && !isShowingCitations) {
        setAnswer(nextAnswer);
      }
    }
  }, [data, index, answer, isShowingCitations, citationAnswers, index]);

  if (level === null) {
    if (!answer)
      return (
        <RowAnswerWrapper>
          {label && (
            <Container verticallyCenter>
              <Body5 bold>{label} </Body5>
            </Container>
          )}

          <Container verticallyCenter vertical={8}>
            <Body5>Working...</Body5>
          </Container>
        </RowAnswerWrapper>
      );

    return (
      <RowAnswerWrapper>
        {label && (
          <Container verticallyCenter>
            <Body5 bold>{label}</Body5>
          </Container>
        )}

        <Container verticallyCenter>
          <ResultOnly
            result={answer}
            isShowingCitations={isShowingCitations}
            enableMoreDetail
            selectedDocs={docIds}
            sourceDocs={sourceDocs}
            model={model}
            userMode={AI_TOOLS.QUICK_LOOK}
            onCitationClick={onCitationClick}
          />
        </Container>
      </RowAnswerWrapper>
    );
  } else {
    if (!answer)
      return (
        <li className={level === 2 ? 'extra-indent' : ''}>
          <RowAnswerListWrapper>
            <Container verticallyCenter>
              <Body5 bold>{label} </Body5>
            </Container>
            <Container verticallyCenter vertical={8}>
              <Body5>Working...</Body5>
            </Container>
          </RowAnswerListWrapper>
        </li>
      );

    return (
      <li className={level === 2 ? 'extra-indent' : ''}>
        <RowAnswerListWrapper>
          {label && (
            <Container verticallyCenter>
              <Body5 bold>{label}</Body5>
            </Container>
          )}

          <Container verticallyCenter>
            <ResultOnly
              result={answer}
              isShowingCitations={isShowingCitations}
              enableMoreDetail
              selectedDocs={docIds}
              sourceDocs={sourceDocs}
              model={model}
              userMode={AI_TOOLS.QUICK_LOOK}
              onCitationClick={onCitationClick}
            />
          </Container>
        </RowAnswerListWrapper>
      </li>
    );
  }
};

const CitationRowWrapper = styled.div`
  padding: 16px 0 16px 0;
  border-bottom: 1px solid ${({ theme }) => theme.themeColors.border};
`;
const CitationIdWrapper = styled.div`
  display: inline;
  padding-right: 8px;
`;
const CitationTextWrapper = styled.div`
  display: inline;
`;
const CitationRow = ({ data }) => {
  return (
    <CitationRowWrapper>
      <CitationIdWrapper>
        <Body5>{data.citationId}&nbsp;&nbsp;&nbsp;</Body5>
        <Body5>{data.filename}</Body5>
        <Body5>#{data.chunkIndex}</Body5>
      </CitationIdWrapper>
      <CitationTextWrapper>
        {data.items.map((item, i) => (
          <Body5 key={i}>
            {item.text}
            <br />
          </Body5>
        ))}
      </CitationTextWrapper>
    </CitationRowWrapper>
  );
};

const CitationsWrapper = styled.div`
  padding: 16px 0 0 0;
`;
const Citations = ({ data }) => {
  if (!data) return null;

  return (
    <CitationsWrapper>
      {data.map((citation, i) => (
        <CitationRow key={citation.citationId} data={citation} />
      ))}
    </CitationsWrapper>
  );
};

export const QuickLookAnalysisResult = ({ resultId, childRecordIds, docIds, sourceDocs, questionList }) => {
  const state = useNewCompanySnapshotStreamingResult(resultId, questionList);
  const { answersLookup, contextLookup, streamEnd, error: streamingError } = state;
  const { getCitations, isGettingCitations, statusCount, citationAnswers, citations } = useOrderedCitations();
  const [queueGetCitations, setQueueGetCitations] = React.useState(false);

  const onGetCitationsClick = () => {
    setQueueGetCitations(true);
  };

  React.useEffect(() => {
    if (queueGetCitations && !isGettingCitations) {
      getCitations(childRecordIds);
    }
  }, [streamEnd]);

  const onQueryDocumentsClick = () => {
    const url = ROUTES.digitalAnalyst + `?doc-ids=${docIds.join(',')}`;
    window.open(url, '_blank');
  };

  const focusCitation = (citationId) => {};

  const onCitationClick = (chunkId, doc, citationId) => {
    focusCitation(citationId);
  };

  return (
    <Page width={'1400px'}>
      <ReportWrapper>
        <Container row verticallyCenter spaceBetween>
          {streamingError ? (
            <Container>
              <Body5>{streamingError}</Body5>
            </Container>
          ) : (
            <Container></Container>
          )}
          <Container left={8}>
            <SkeletonButton onClick={onQueryDocumentsClick}>Query Documents</SkeletonButton>
          </Container>
        </Container>

        <Container top={24}>
          <SectionsWrapper>
            <SectionWrapper>
              <Container>
                <Body5 uppercase bold>
                  Bull Case Mode:
                </Body5>
                <Container top={16}>
                  <ul>
                    <RowAnswer
                      data={answersLookup}
                      label={'Executive Summary:'}
                      contextLookup={contextLookup}
                      citationAnswers={citationAnswers}
                      sourceDocs={sourceDocs}
                      onCitationClick={onCitationClick}
                      index={0}
                      childRecordIds={childRecordIds}
                      docIds={docIds}
                    />
                    <RowAnswer
                      data={answersLookup}
                      label={'Structural or Cyclical Bull Case:'}
                      index={1}
                      contextLookup={contextLookup}
                      citationAnswers={citationAnswers}
                      sourceDocs={sourceDocs}
                      onCitationClick={onCitationClick}
                      childRecordIds={childRecordIds}
                      docIds={docIds}
                    />
                    <RowAnswer
                      data={answersLookup}
                      label={'“Heart” of Bull Case Thesis:'}
                      contextLookup={contextLookup}
                      citationAnswers={citationAnswers}
                      sourceDocs={sourceDocs}
                      index={2}
                      childRecordIds={childRecordIds}
                      docIds={docIds}
                      onCitationClick={onCitationClick}
                    />
                    <RowAnswer
                      data={answersLookup}
                      label={'Market Share/Pricing Power:'}
                      contextLookup={contextLookup}
                      citationAnswers={citationAnswers}
                      sourceDocs={sourceDocs}
                      index={3}
                      childRecordIds={childRecordIds}
                      docIds={docIds}
                      onCitationClick={onCitationClick}
                    />
                    <RowAnswer
                      data={answersLookup}
                      label={'AI-Impact:'}
                      contextLookup={contextLookup}
                      citationAnswers={citationAnswers}
                      sourceDocs={sourceDocs}
                      index={4}
                      childRecordIds={childRecordIds}
                      docIds={docIds}
                      onCitationClick={onCitationClick}
                    />
                    <RowAnswer
                      data={answersLookup}
                      label={'Management:'}
                      contextLookup={contextLookup}
                      citationAnswers={citationAnswers}
                      sourceDocs={sourceDocs}
                      index={5}
                      childRecordIds={childRecordIds}
                      docIds={docIds}
                      onCitationClick={onCitationClick}
                    />
                    <RowAnswer
                      data={answersLookup}
                      label={'Other:'}
                      contextLookup={contextLookup}
                      citationAnswers={citationAnswers}
                      sourceDocs={sourceDocs}
                      index={6}
                      childRecordIds={childRecordIds}
                      docIds={docIds}
                      onCitationClick={onCitationClick}
                    />
                    <RowAnswer
                      data={answersLookup}
                      label={'Catalysts:'}
                      contextLookup={contextLookup}
                      citationAnswers={citationAnswers}
                      sourceDocs={sourceDocs}
                      index={7}
                      childRecordIds={childRecordIds}
                      docIds={docIds}
                      onCitationClick={onCitationClick}
                    />
                    <RowAnswer
                      data={answersLookup}
                      label={'Upside to consensus/current Guidance:'}
                      contextLookup={contextLookup}
                      citationAnswers={citationAnswers}
                      sourceDocs={sourceDocs}
                      index={8}
                      childRecordIds={childRecordIds}
                      docIds={docIds}
                      onCitationClick={onCitationClick}
                    />
                    <RowAnswer
                      data={answersLookup}
                      label={'Upside to the math:'}
                      contextLookup={contextLookup}
                      citationAnswers={citationAnswers}
                      sourceDocs={sourceDocs}
                      index={9}
                      childRecordIds={childRecordIds}
                      docIds={docIds}
                      onCitationClick={onCitationClick}
                      level={2}
                    />
                    <RowAnswer
                      data={answersLookup}
                      label={'Valuation:'}
                      contextLookup={contextLookup}
                      citationAnswers={citationAnswers}
                      sourceDocs={sourceDocs}
                      index={10}
                      childRecordIds={childRecordIds}
                      docIds={docIds}
                      onCitationClick={onCitationClick}
                    />
                  </ul>
                </Container>
              </Container>
            </SectionWrapper>
            <Container>
              <Body5 uppercase bold>
                Summary Bear Cases and Mitigants
              </Body5>
              <Container top={16}>
                <RowAnswer
                  data={answersLookup}
                  label={''}
                  contextLookup={contextLookup}
                  citationAnswers={citationAnswers}
                  sourceDocs={sourceDocs}
                  index={11}
                  childRecordIds={childRecordIds}
                  docIds={docIds}
                  onCitationClick={onCitationClick}
                  level={null}
                />
              </Container>
            </Container>
          </SectionsWrapper>
        </Container>
        <Container>
          <SectionsWrapper>
            <SectionWrapper>
              <Container>
                <Body5 uppercase bold>
                  Bear Case Mode:
                </Body5>
                <Container top={16}>
                  <ul>
                    <RowAnswer
                      data={answersLookup}
                      contextLookup={contextLookup}
                      citationAnswers={citationAnswers}
                      sourceDocs={sourceDocs}
                      label={'Executive Summary:'}
                      index={12}
                      childRecordIds={childRecordIds}
                      docIds={docIds}
                      onCitationClick={onCitationClick}
                    />
                    <RowAnswer
                      data={answersLookup}
                      label={'Structural or Cyclical Bear Case:'}
                      index={13}
                      childRecordIds={childRecordIds}
                      contextLookup={contextLookup}
                      citationAnswers={citationAnswers}
                      sourceDocs={sourceDocs}
                      docIds={docIds}
                      onCitationClick={onCitationClick}
                    />
                    <RowAnswer
                      data={answersLookup}
                      label={'“Heart” of bear case Thesis:'}
                      index={14}
                      docIds={docIds}
                      childRecordIds={childRecordIds}
                      contextLookup={contextLookup}
                      citationAnswers={citationAnswers}
                      sourceDocs={sourceDocs}
                      onCitationClick={onCitationClick}
                    />
                    <RowAnswer
                      data={answersLookup}
                      label={'Competition/Pricing Pressure:'}
                      index={15}
                      childRecordIds={childRecordIds}
                      contextLookup={contextLookup}
                      citationAnswers={citationAnswers}
                      sourceDocs={sourceDocs}
                      docIds={docIds}
                      onCitationClick={onCitationClick}
                    />
                    <RowAnswer
                      data={answersLookup}
                      label={'AI-Impact:'}
                      contextLookup={contextLookup}
                      sourceDocs={sourceDocs}
                      citationAnswers={citationAnswers}
                      index={16}
                      childRecordIds={childRecordIds}
                      docIds={docIds}
                      onCitationClick={onCitationClick}
                    />
                    <RowAnswer
                      data={answersLookup}
                      label={'Management:'}
                      contextLookup={contextLookup}
                      citationAnswers={citationAnswers}
                      sourceDocs={sourceDocs}
                      index={17}
                      childRecordIds={childRecordIds}
                      docIds={docIds}
                      onCitationClick={onCitationClick}
                    />
                    <RowAnswer
                      data={answersLookup}
                      label={'Other:'}
                      contextLookup={contextLookup}
                      sourceDocs={sourceDocs}
                      citationAnswers={citationAnswers}
                      index={18}
                      childRecordIds={childRecordIds}
                      docIds={docIds}
                      onCitationClick={onCitationClick}
                    />
                    <RowAnswer
                      data={answersLookup}
                      label={'Catalysts:'}
                      contextLookup={contextLookup}
                      citationAnswers={citationAnswers}
                      sourceDocs={sourceDocs}
                      index={19}
                      childRecordIds={childRecordIds}
                      docIds={docIds}
                      onCitationClick={onCitationClick}
                    />
                    <RowAnswer
                      data={answersLookup}
                      label={'Risk to consensus/current Guidance:'}
                      index={20}
                      childRecordIds={childRecordIds}
                      contextLookup={contextLookup}
                      citationAnswers={citationAnswers}
                      sourceDocs={sourceDocs}
                      docIds={docIds}
                      onCitationClick={onCitationClick}
                    />
                    <RowAnswer
                      data={answersLookup}
                      label={'Need to believe the math:'}
                      index={21}
                      childRecordIds={childRecordIds}
                      contextLookup={contextLookup}
                      sourceDocs={sourceDocs}
                      citationAnswers={citationAnswers}
                      docIds={docIds}
                      level={2}
                      onCitationClick={onCitationClick}
                    />
                    <RowAnswer
                      data={answersLookup}
                      label={'Valuation:'}
                      contextLookup={contextLookup}
                      citationAnswers={citationAnswers}
                      sourceDocs={sourceDocs}
                      index={22}
                      childRecordIds={childRecordIds}
                      docIds={docIds}
                      onCitationClick={onCitationClick}
                    />
                  </ul>
                </Container>
              </Container>
            </SectionWrapper>
            <Container>
              <Body5 uppercase bold>
                Summary Bull Cases and Mitigants
              </Body5>
              <Container top={16}>
                <RowAnswer
                  data={answersLookup}
                  label={''}
                  contextLookup={contextLookup}
                  citationAnswers={citationAnswers}
                  sourceDocs={sourceDocs}
                  index={23}
                  childRecordIds={childRecordIds}
                  docIds={docIds}
                  level={null}
                  onCitationClick={onCitationClick}
                />
              </Container>
            </Container>
          </SectionsWrapper>
        </Container>
        <Container top={24}>
          <Body1 bold>Citations</Body1>
          {queueGetCitations && (citations === null || citations.length === 0) ? (
            <Container top={16}>
              <Loading noVerticalPadding message={'Working On Citations'} />
              {statusCount > 0 ? (
                <Container top={16}>
                  <Body5 bold>{statusCount} out of 23</Body5>
                </Container>
              ) : null}
            </Container>
          ) : null}
          {citations && <Citations data={citations} />}
        </Container>
        {!queueGetCitations && (
          <Container top={16}>
            <SkeletonButton onClick={onGetCitationsClick}>Get Citations</SkeletonButton>
          </Container>
        )}
      </ReportWrapper>
    </Page>
  );
};
