import React from 'react';
import styled from 'styled-components';
import { Header } from '../components/navigation';
import { Body, FooterSeparator } from '../components/layout/Body';
import { useNavigate } from 'react-router';
import { isUndefinedOrNull, logger, scrollToTop } from '../utils/usefulFuncs';
import { Body5, Container, H5, Page } from '../libs/nvstr-common-ui.es';
import { AdvancedSettingsFields, useAdvancedSettings } from '../hooks/application/useAdvancedSettings';
import { AI_TOOLS, CURRENT_DEFAULT_MODEL } from '../constants';
import { useDispatch } from 'react-redux';
import { applicationActionTypes } from '../constants/actionTypes';
import BackArrowButton from '../components/buttons/BackArrowButton';
import { Spinner } from '../components/UI/LoadingSpinner';
import { AdvancedSettingsButton } from '../containers/DigitalAnalyst/AdvancedSettings';
import { CompanySelectionInput } from '../containers/DigitalAnalyst/components/CompanySelectionInput';
import { FormState } from '../components/UI/FormState';
import { FlatButton } from '../components/buttons';
import { SkeletonButton } from '../components/buttons/SkeletonButton';
import { ROUTES } from '../constants/routes';
import { fetchFilteredDocs } from '../hooks/features/useDocs';
import { GRID_MODES } from '../containers/gridMode';
import { getResultId } from '../hooks/features/useAskDigitalAnalyst';
import { QuestionResult } from '../containers/DigitalAnalyst/components/QuestionResult';
import { createTimeInstance } from '../libs/nvstr-utils.es';
import { getComparisonAnswer } from '../hooks/features/useTableAnalysis';
import { useNewCompanySnapshotStreamingResult } from '../hooks/features/useNewCompanySnapshotStreamingResult';
import { Working } from '../components/UI/Working';
import { Answer } from '../containers/DigitalAnalyst/components/AnswerLine';
import ErrorText from '../components/UI/ErrorText';

const PageWrapper = styled.div`
  + div {
    width: 100%;
  }
`;
const SubmitButtonWrapper = styled.div`
  text-align: center;
  width: 100%;
`;
const FormWrapper = styled.div`
  input {
    border: 1px solid ${({ theme }) => theme.themeColors.border};
    color: ${({ theme }) => theme.themeColors.text};
    background-color: transparent;
    border-radius: 6px;
    width: 100%;
    padding: 0 !important;
  }

  select {
    border: 1px solid ${({ theme }) => theme.themeColors.border};
    color: ${({ theme }) => theme.themeColors.text};
    background-color: transparent;
    border-radius: 6px;
    width: 100%;
    padding: 12px 16px;
    -webkit-appearance: auto !important;
  }

  textarea {
    border: 1px solid ${({ theme }) => theme.themeColors.border};
    color: ${({ theme }) => theme.themeColors.text};
    background-color: transparent;
    border-radius: 6px;
    min-height: 100px;
    width: 100%;
    padding: 16px;
  }
`;

const advancedSettingsFields = [
  AdvancedSettingsFields.model,
  AdvancedSettingsFields.maxContextLength,
  AdvancedSettingsFields.smoothingToggle,
  AdvancedSettingsFields.includeUserDocsToggle,
  AdvancedSettingsFields.maxContextChunks,
];

const defaultAdvancedSettings = {
  model: CURRENT_DEFAULT_MODEL,
  enableSmoothing: false,
  includeUserDocs: true,
  maxContextLength: '',
  maxAnswerLength: '',
};

const tool = AI_TOOLS.ESG_UPDATES;

const WORKING_STATES = {
  gettingDocs: 'gd',
  gettingResults: 'gr',
};

const SUMMARY_PROMPT = "Summarize the company's ESG policies in detail. Break each section out.";
const SUMMARY_SECTION_DISPLAY_QUESTION = 'ESG Policy Summary (last 12 months)';
const COMPARISON_QUESTION =
  "Compare the commentary and disclosures related to ESG from the company's filings in the last 12 months, to those from the previous 12 months";
const COMPARISON_PROMPT = {
  display_label: 'COMPARISON ESG',
  id: 99999999,
  is_default: false,
  is_diff_mode: true,
  is_my_prompt: true,
  is_shared: false,
  prompt: COMPARISON_QUESTION,
};
const COMPARISON_SECTION_DISPLAY_QUESTION = 'ESG Policy Comparison (last 2 years)';

function ComparisonAnswer({ resultId }) {
  const { answersLookup, error: streamError, streamEnd } = useNewCompanySnapshotStreamingResult(resultId);

  if (!resultId || !answersLookup) {
    return (
      <Container>
        <Working />
      </Container>
    );
  }

  if (streamError) {
    return (
      <Container>
        <ErrorText>Something went wrong getting answer, please try again.</ErrorText>
      </Container>
    );
  }
  return (
    <Container>
      <Answer result={answersLookup[0]} />
    </Container>
  );
}

function ESGUpdatesTool({ onGoBack }) {
  React.useEffect(() => {
    dispatch({
      type: applicationActionTypes.setAdvancedSettings,
      payload: defaultAdvancedSettings,
    });
  }, []);

  const { model } = useAdvancedSettings();
  const dispatch = useDispatch();

  const [company, setCompany] = React.useState(null);
  const [workingState, setWorkingState] = React.useState(null);
  const [formError, setFormError] = React.useState(null);

  const [summaryResultId, setSummaryResultId] = React.useState(null);
  const [comparisonResultId, setComparisonResultId] = React.useState(null);

  React.useEffect(() => {
    if (summaryResultId) {
      const id = 'snapshot-answers-section';
      const yOffset = -26;
      const element = document.getElementById(id);
      if (element) {
        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

        setTimeout(() => {
          window.scrollTo({ top: y, behavior: 'smooth' });
        }, 300);
      }
    }
  }, [summaryResultId]);

  const onSubmit = async () => {
    setWorkingState(WORKING_STATES.gettingDocs);
    setFormError(null);

    const filters = {
      docMode: GRID_MODES.esg,
      ticker: company.symbol,
    };
    const { status, data } = await fetchFilteredDocs(filters);
    if (status !== 200) {
      setFormError('Something went wrong, please try again.');
      setWorkingState(null);
      return;
    }
    const docsLookup = data;
    const docs = Object.values(docsLookup)[0];

    setWorkingState(WORKING_STATES.gettingResults);

    const oneYearAgo = createTimeInstance().subtract(1, 'year');
    const twoYearAgo = createTimeInstance().subtract(2, 'year');
    const last12MonthsDocs = docs.filter(
      (d) => !isUndefinedOrNull(d.report_date) && createTimeInstance(d.report_date).isAfter(oneYearAgo)
    );
    const last12to24MonthsDocs = docs.filter(
      (d) =>
        !isUndefinedOrNull(d.report_date) &&
        createTimeInstance(d.report_date).isBefore(oneYearAgo) &&
        createTimeInstance(d.report_date).isAfter(twoYearAgo)
    );

    logger(
      'reports used',
      { last12MonthsDocs: last12MonthsDocs.map((d) => d.report_date) },
      { last12to24MonthsDocs: last12to24MonthsDocs.map((d) => d.report_date) }
    );

    const params = {
      selectedDocs: last12MonthsDocs,
      screen_mode: false,

      tool,

      model,
      enableFollowUpQuestions: false,
    };
    const { id: resultId, error } = await getResultId(SUMMARY_PROMPT, params, {});
    if (resultId) {
      setSummaryResultId(resultId);
      setWorkingState(null);
    } else {
      setFormError('Something went wrong, try again');
    }

    const comparisonParams = {
      docIdLists: [last12MonthsDocs.map((d) => d.id), last12to24MonthsDocs.map((d) => d.id)],

      tool,
      model,
    };
    const { resultId: comparisonResultId } = await getComparisonAnswer(COMPARISON_PROMPT, comparisonParams, {});
    if (comparisonResultId) {
      setComparisonResultId(comparisonResultId);
    } else {
      setFormError('Something went wrong, try again');
    }
  };

  const onClear = () => {
    setCompany(null);
    setSummaryResultId(null);
    setComparisonResultId(null);
    setFormError(null);
  };

  const onAddTicker = (t) => {
    setCompany(t);
  };

  const onRemoveTicker = () => {
    setCompany(null);
  };

  const handleClearSnapshot = () => {
    onClear();
  };

  return (
    <PageWrapper>
      <Page width={summaryResultId ? '1000px' : '800px'}>
        <BackArrowButton onClick={onGoBack} />
        <Container top={40}>
          <H5>ESG Updates</H5>
        </Container>
        {workingState === WORKING_STATES.gettingDocs ? (
          <Container top={80} bottom={80} centerAll>
            <Container>
              <Container centerAll>
                <Spinner />
              </Container>
              <Container top={24} left={12}>
                <Body5>Working...</Body5>
              </Container>
            </Container>
          </Container>
        ) : (
          <Container top={8}>
            <FormWrapper>
              <AdvancedSettingsButton fields={advancedSettingsFields} />
              <Container top={24}>
                <Container bottom={8}>
                  <Body5>Select Company</Body5>
                </Container>
                <CompanySelectionInput
                  value={company}
                  label={null}
                  onAddCompany={onAddTicker}
                  onRemoveCompany={onRemoveTicker}
                  placeholder={'Search Companies...'}
                  alwaysHideClear
                />
              </Container>
              <Container top={24}>
                <Container centerAll>
                  <FormState error={formError} />
                </Container>
                <SubmitButtonWrapper>
                  <FlatButton disabled={company === null} onClick={onSubmit} fullWidth>
                    Get Updates
                  </FlatButton>
                </SubmitButtonWrapper>
              </Container>
            </FormWrapper>
          </Container>
        )}
      </Page>
      {(workingState === WORKING_STATES.gettingResults || summaryResultId) && (
        <Page width={'1000px'}>
          <div id={'snapshot-answers-section'} />
          <Container top={16}>
            <Container bottom={4}>
              <H5>{SUMMARY_SECTION_DISPLAY_QUESTION}</H5>
            </Container>
            <QuestionResult
              isSubmittingToApi={false}
              resultId={summaryResultId}
              userMode={tool}
              question={null}
              enableQualityCheck
              enableFollowUpQuestions={false}
              collapseHeight
            />
          </Container>
          <Container top={24} />
          <FooterSeparator />
          <Container top={16}>
            <Container bottom={4}>
              <H5>{COMPARISON_SECTION_DISPLAY_QUESTION}</H5>
            </Container>
            <Container>
              <ComparisonAnswer resultId={comparisonResultId} />
            </Container>
          </Container>
          {workingState === null && (
            <Container top={24} centerAll>
              <SkeletonButton onClick={handleClearSnapshot}>Close</SkeletonButton>
            </Container>
          )}
        </Page>
      )}
    </PageWrapper>
  );
}

function ESGUpdatesPage() {
  const navigate = useNavigate();

  const onGoBack = () => navigate(ROUTES.home);

  React.useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <PageWrapper>
      <Header />
      <Body withFooter>
        <ESGUpdatesTool onGoBack={onGoBack} />
      </Body>
    </PageWrapper>
  );
}

export default ESGUpdatesPage;
