import React from 'react';
import styled from 'styled-components';
import { Body5, Container, H5 } from '../../libs/nvstr-common-ui.es';
import { ResultOnly } from '../../containers/DigitalAnalyst/components/AnswerLine';
import { ROUTES } from '../../constants/routes';
import { SkeletonButton } from '../buttons/SkeletonButton';

const Wrapper = styled.div`
  padding: 16px 0 0 0;
`;

const OverviewTable = styled.table`
  border-radius: 4px;
  width: 100%;

  td {
    vertical-align: top;

    border: 1px solid ${({ theme }) => theme.themeColors.text};
    padding: 4px 8px;
    width: 50%;
  }
`;
const ItemTableWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;
const ItemTable = styled.table`
  margin-top: 24px;

  border-radius: 4px;
  //width: 100%;
  table-layout: fixed;

  th,
  td {
    border: 1px solid ${({ theme }) => theme.themeColors.text};
    padding: 4px 8px;
  }

  td {
    vertical-align: center;
    width: 40px;
  }

  td:nth-child(1),
  td:nth-child(3) {
    min-width: 180px;
  }

  th:nth-child(2),
  th:nth-child(4),
  td:nth-child(2),
  td:nth-child(4) {
    text-align: center;
  }

  td:nth-child(2),
  td:nth-child(4) {
    width: 80px;
  }
`;

const MiscTable = styled.table`
  margin-top: 24px;

  border-radius: 4px;
  width: 100%;

  td {
    vertical-align: top;

    border: 1px solid ${({ theme }) => theme.themeColors.text};
    padding: 4px 8px;
    width: 50%;
  }
`;

const KeyValue = ({ keyName, templateKey, answers }) => {
  const answerValue = answers[templateKey] || 'Working...';

  return (
    <Container>
      <Body5 bold>{keyName}: </Body5>
      {templateKey ? <ResultOnly result={answerValue} /> : null}
    </Container>
  );
};

const YNU = ({ answers, templateKey }) => {
  const answerValue = answers[templateKey] || 'Working...';
  if (!templateKey) return null;
  return <ResultOnly result={answerValue} />;
};

const TextAnswer = ({ answers, templateKey }) => {
  const answerValue = answers[templateKey] || 'Working...';
  return <ResultOnly result={answerValue} />;
};

export const NewDealTriage = ({ answers, selectedDocs }) => {
  if (!answers) {
    return null;
  }

  const onQueryDocumentsClick = () => {
    const url = ROUTES.digitalAnalyst + `?doc-ids=${selectedDocs.map((d) => d.id).join(',')}`;
    window.open(url, '_blank');
  };

  return (
    <Wrapper>
      <Container row verticallyCenter spaceBetween bottom={24}>
        <H5>Confidential</H5>
        <Container left={8}>
          <SkeletonButton onClick={onQueryDocumentsClick}>Query Documents</SkeletonButton>
        </Container>
      </Container>
      <OverviewTable>
        <tbody>
          <tr>
            <td>
              <KeyValue keyName={'Company Name'} answers={answers} templateKey={'{{TEMPLATE_1}}'} />
            </td>
            <td>
              <Container row verticallyCentered>
                <KeyValue keyName={'Date Received'} answers={answers} templateKey={'{{TEMPLATE_2}}'} />
                <Body5>&nbsp;&nbsp;//&nbsp;&nbsp;</Body5>
                <KeyValue keyName={'Date Completed'} answers={answers} templateKey={'{{TEMPLATE_3}}'} />
              </Container>
            </td>
          </tr>
          <tr>
            <td>
              <Container row verticallyCentered>
                <KeyValue keyName={'Seller'} answers={answers} templateKey={'{{TEMPLATE_4}}'} />
                <Body5>&nbsp;&nbsp;//&nbsp;&nbsp;</Body5>
                <KeyValue keyName={'Banker'} answers={answers} templateKey={'{{TEMPLATE_5}}'} />
              </Container>
            </td>
            <td>
              <KeyValue keyName={'Sponsors to Date'} answers={answers} templateKey={'{{TEMPLATE_6}}'} />
            </td>
          </tr>
          <tr>
            <td>
              <KeyValue keyName={'Triage Team'} answers={answers} templateKey={'{{TEMPLATE_7}}'} />
            </td>
            <td>
              <KeyValue keyName={'Incumbent Lenders'} answers={answers} templateKey={'{{TEMPLATE_8}}'} />
            </td>
          </tr>
          <tr>
            <td>
              <Container row verticallyCentered>
                <KeyValue keyName={'Year Founded'} answers={answers} templateKey={'{{TEMPLATE_9}}'} />
                <Body5>&nbsp;&nbsp;//&nbsp;&nbsp;</Body5>
                <KeyValue keyName={'Headquarters'} answers={answers} templateKey={'{{TEMPLATE_10}}'} />
              </Container>
            </td>
            <td>
              <KeyValue keyName={'Process Timetable'} answers={answers} templateKey={'{{TEMPLATE_11}}'} />
            </td>
          </tr>
          <tr>
            <td>
              <KeyValue keyName={'ESG Category Risk'} answers={answers} templateKey={null} />
            </td>
            <td>
              <KeyValue keyName={'GC History (per CRM)'} answers={answers} templateKey={null} />
            </td>
          </tr>

          <tr>
            <td colSpan={2}>
              <KeyValue keyName={'Overview'} answers={answers} templateKey={'{{TEMPLATE_14}}'} />
              <Container top={8} left={16}>
                <TextAnswer answers={answers} templateKey={'{{TEMPLATE_15}}'} />
              </Container>
              <Container top={8} left={16}>
                <KeyValue keyName={'FYE Revenue / Adj. EBITDA'} answers={answers} templateKey={'{{TEMPLATE_16}}'} />
              </Container>
            </td>
          </tr>
          <tr>
            <td>
              <Container top={8}>
                <Body5 bold>Financials: </Body5>
              </Container>
              <Container top={8}>
                <TextAnswer answers={answers} templateKey={'{{TEMPLATE_17}}'} />
              </Container>
            </td>
            <td>
              <Container top={8}>
                <Body5 bold>Financial Notes: </Body5>
              </Container>
              <Container top={8}>
                <TextAnswer answers={answers} templateKey={'{{TEMPLATE_18}}'} />
              </Container>
            </td>
          </tr>
        </tbody>
      </OverviewTable>
      <ItemTableWrapper>
        <ItemTable>
          <colgroup>
            <col style={{ minWidth: '240px' }} />
            <col />
            {/*<col />*/}
            {/*<col />*/}
            <col style={{ minWidth: '240px' }} />
            <col />
            {/*<col />*/}
            {/*<col />*/}
          </colgroup>
          <thead>
            <tr>
              <th>Item</th>
              <th>Y/N/U</th>
              {/*<th>N</th>*/}
              {/*<th>U</th>*/}
              <th>Item</th>
              <th>Y/N/U</th>
              {/*<th>N</th>*/}
              {/*<th>U</th>*/}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <Body5 bold>Concentrations</Body5>
              </td>
              <td>
                <YNU answers={answers} templateKey={'{{TEMPLATE_19}}'} />
              </td>
              {/*<td>*/}
              {/*  <YNU answers={answers} templateKey={'{{TEMPLATE_20}}'} />*/}
              {/*</td>*/}
              {/*<td>*/}
              {/*  <YNU answers={answers} templateKey={'{{TEMPLATE_21}}'} />*/}
              {/*</td>*/}
              <td>
                <Body5 bold>Capital Exp. Excess</Body5>
              </td>
              <td>
                <YNU answers={answers} templateKey={'{{TEMPLATE_22}}'} />
              </td>
              {/*<td>*/}
              {/*  <YNU answers={answers} templateKey={'{{TEMPLATE_23}}'} />*/}
              {/*</td>*/}
              {/*<td>*/}
              {/*  <YNU answers={answers} templateKey={'{{TEMPLATE_24}}'} />*/}
              {/*</td>*/}
            </tr>
            <tr>
              <td>
                <Body5 bold>Cyclicality</Body5>
              </td>
              <td>
                <YNU answers={answers} templateKey={'{{TEMPLATE_31}}'} />
              </td>
              {/*<td>*/}
              {/*  <YNU answers={answers} templateKey={'{{TEMPLATE_32}}'} />*/}
              {/*</td>*/}
              {/*<td>*/}
              {/*  <YNU answers={answers} templateKey={'{{TEMPLATE_33}}'} />*/}
              {/*</td>*/}
              <td>
                <Body5 bold>Excessive Revolver</Body5>
              </td>
              <td>
                <YNU answers={answers} templateKey={'{{TEMPLATE_34}}'} />
              </td>
              {/*<td>*/}
              {/*  <YNU answers={answers} templateKey={'{{TEMPLATE_35}}'} />*/}
              {/*</td>*/}
              {/*<td>*/}
              {/*  <YNU answers={answers} templateKey={'{{TEMPLATE_36}}'} />*/}
              {/*</td>*/}
            </tr>
            <tr>
              <td>
                <Body5 bold>Severe Seasonality</Body5>
              </td>
              <td>
                <YNU answers={answers} templateKey={'{{TEMPLATE_43}}'} />
              </td>
              {/*<td>*/}
              {/*  <YNU answers={answers} templateKey={'{{TEMPLATE_44}}'} />*/}
              {/*</td>*/}
              {/*<td>*/}
              {/*  <YNU answers={answers} templateKey={'{{TEMPLATE_45}}'} />*/}
              {/*</td>*/}
              <td>
                <Body5 bold>Bonding / Surety</Body5>
              </td>
              <td>
                <YNU answers={answers} templateKey={'{{TEMPLATE_46}}'} />
              </td>
              {/*<td>*/}
              {/*  <YNU answers={answers} templateKey={'{{TEMPLATE_47}}'} />*/}
              {/*</td>*/}
              {/*<td>*/}
              {/*  <YNU answers={answers} templateKey={'{{TEMPLATE_48}}'} />*/}
              {/*</td>*/}
            </tr>
            <tr>
              <td>
                <Body5 bold>Large WC Needs</Body5>
              </td>
              <td>
                <YNU answers={answers} templateKey={'{{TEMPLATE_52}}'} />
              </td>
              {/*<td>*/}
              {/*  <YNU answers={answers} templateKey={'{{TEMPLATE_53}}'} />*/}
              {/*</td>*/}
              {/*<td>*/}
              {/*  <YNU answers={answers} templateKey={'{{TEMPLATE_54}}'} />*/}
              {/*</td>*/}
              <td>
                <Body5 bold>FX Exposure</Body5>
              </td>
              <td>
                <YNU answers={answers} templateKey={'{{TEMPLATE_55}}'} />
              </td>
              {/*<td>*/}
              {/*  <YNU answers={answers} templateKey={'{{TEMPLATE_56}}'} />*/}
              {/*</td>*/}
              {/*<td>*/}
              {/*  <YNU answers={answers} templateKey={'{{TEMPLATE_57}}'} />*/}
              {/*</td>*/}
            </tr>
          </tbody>
        </ItemTable>
        <ItemTable>
          <colgroup>
            <col style={{ minWidth: '240px' }} />
            <col />
            {/*<col />*/}
            {/*<col />*/}
            <col style={{ minWidth: '240px' }} />
            <col />
            {/*<col />*/}
            {/*<col />*/}
          </colgroup>
          <thead>
            <tr>
              <th>Item</th>
              <th>Y/N/U</th>
              {/*<th>N</th>*/}
              {/*<th>U</th>*/}
              <th>Item</th>
              <th>Y/N/U</th>
              {/*<th>N</th>*/}
              {/*<th>U</th>*/}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <Body5 bold>Management Issues</Body5>
              </td>
              <td>
                <YNU answers={answers} templateKey={'{{TEMPLATE_25}}'} />
              </td>
              {/*<td>*/}
              {/*  <YNU answers={answers} templateKey={'{{TEMPLATE_26}}'} />*/}
              {/*</td>*/}
              {/*<td>*/}
              {/*  <YNU answers={answers} templateKey={'{{TEMPLATE_27}}'} />*/}
              {/*</td>*/}
              <td>
                <Body5 bold>Project Based</Body5>
              </td>
              <td>
                <YNU answers={answers} templateKey={'{{TEMPLATE_28}}'} />
              </td>
              {/*<td>*/}
              {/*  <YNU answers={answers} templateKey={'{{TEMPLATE_29}}'} />*/}
              {/*</td>*/}
              {/*<td>*/}
              {/*  <YNU answers={answers} templateKey={'{{TEMPLATE_30}}'} />*/}
              {/*</td>*/}
            </tr>
            <tr>
              <td>
                <Body5 bold>Background Issue</Body5>
              </td>
              <td>
                <YNU answers={answers} templateKey={'{{TEMPLATE_37}}'} />
              </td>
              {/*<td>*/}
              {/*  <YNU answers={answers} templateKey={'{{TEMPLATE_38}}'} />*/}
              {/*</td>*/}
              {/*<td>*/}
              {/*  <YNU answers={answers} templateKey={'{{TEMPLATE_39}}'} />*/}
              {/*</td>*/}
              <td>
                <Body5 bold>Unique Accounting</Body5>
              </td>
              <td>
                <YNU answers={answers} templateKey={'{{TEMPLATE_40}}'} />
              </td>
              {/*<td>*/}
              {/*  <YNU answers={answers} templateKey={'{{TEMPLATE_41}}'} />*/}
              {/*</td>*/}
              {/*<td>*/}
              {/*  <YNU answers={answers} templateKey={'{{TEMPLATE_42}}'} />*/}
              {/*</td>*/}
            </tr>
            <tr>
              <td>
                <Body5 bold>Raw Material Vol.</Body5>
              </td>
              <td>
                <YNU answers={answers} templateKey={'{{TEMPLATE_49}}'} />
              </td>
              {/*<td>*/}
              {/*  <YNU answers={answers} templateKey={'{{TEMPLATE_50}}'} />*/}
              {/*</td>*/}
              {/*<td>*/}
              {/*  <YNU answers={answers} templateKey={'{{TEMPLATE_51}}'} />*/}
              {/*</td>*/}
              <td>
                <Body5 bold></Body5>
              </td>
              <td>
                <YNU answers={answers} templateKey={null} />
              </td>
              {/*<td>*/}
              {/*  <YNU answers={answers} templateKey={null} />*/}
              {/*</td>*/}
              {/*<td>*/}
              {/*  <YNU answers={answers} templateKey={null} />*/}
              {/*</td>*/}
            </tr>
            <tr>
              <td>
                <Body5 bold>Regulatory / Leg. Risk</Body5>
              </td>
              <td>
                <YNU answers={answers} templateKey={'{{TEMPLATE_58}}'} />
              </td>
              {/*<td>*/}
              {/*  <YNU answers={answers} templateKey={'{{TEMPLATE_59}}'} />*/}
              {/*</td>*/}
              {/*<td>*/}
              {/*  <YNU answers={answers} templateKey={'{{TEMPLATE_60}}'} />*/}
              {/*</td>*/}
              <td>
                <Body5 bold></Body5>
              </td>
              <td>
                <YNU answers={answers} templateKey={null} />
              </td>
              {/*<td>*/}
              {/*  <YNU answers={answers} templateKey={null} />*/}
              {/*</td>*/}
              {/*<td>*/}
              {/*  <YNU answers={answers} templateKey={null} />*/}
              {/*</td>*/}
            </tr>
          </tbody>
        </ItemTable>
      </ItemTableWrapper>
      <MiscTable>
        <tbody>
          <tr>
            <td>
              <Container>
                <Body5 bold>Strengths: </Body5>
              </Container>
              <Container top={8} left={16}>
                <TextAnswer answers={answers} templateKey={'{{TEMPLATE_61}}'} />
              </Container>
            </td>

            <td>
              <Container>
                <Body5 bold>Areas of Concern: </Body5>
              </Container>
              <Container top={8} left={16}>
                <TextAnswer answers={answers} templateKey={'{{TEMPLATE_62}}'} />
              </Container>
            </td>
          </tr>
        </tbody>
      </MiscTable>
    </Wrapper>
  );
};
