import React from 'react';
import { useSelector } from 'react-redux';
import { addOptionalField } from '../../utils/application';
import { handle400Statuses, sendApiRequest } from '../../services/api';
import { logNetRequest } from '../../utils/usefulFuncs';

export const getTableAnswer = async (prompt, params = {}, options = {}) => {
  const URL = `v1/genai_query_grid`;

  const { docIdLists, tool, model, maxAnswerLength, maxContextLength, maxContextChunks, seed, temperature, topP } =
    params;

  let form = {
    prompts: [prompt],
    doc_id_lists: docIdLists,
    user_mode: tool,
    model,
    max_answer_tokens: maxAnswerLength || null,
    max_context_tokens: isNaN(maxContextLength) ? 32000 : parseInt(maxContextLength, 10),
  };
  addOptionalField('max_context_chunks', maxContextChunks, form);
  addOptionalField('seed', seed, form);
  addOptionalField('temperature', temperature, form, true);
  addOptionalField('top_p', topP, form, true);
  const { status, data, error } = await sendApiRequest('post', URL, form);
  handle400Statuses(status);
  logNetRequest(URL, status, data);

  if (status === 200) {
    const row = data.table[0];
    const cells = row.cells;
    const resultId = data.result_id;
    return {
      success: true,
      cells,
      resultId,
    };
  } else {
    return {
      error: 'Something went wrong, please try again.',
    };
  }
};

export const getComparisonAnswer = async (prompt, params, options = {}) => {
  const URL = `v1/genai_query_grid`;

  const { docIdLists, tool, model, maxAnswerLength, maxContextLength, maxContextChunks, seed, temperature, topP } =
    params;

  let form = {
    prompts: [prompt],
    doc_id_lists: docIdLists,
    user_mode: tool,
    model,
    max_answer_tokens: maxAnswerLength || null,
    max_context_tokens: isNaN(maxContextLength) ? 32000 : parseInt(maxContextLength, 10),
  };
  addOptionalField('max_context_chunks', maxContextChunks, form);
  addOptionalField('seed', seed, form);
  addOptionalField('temperature', temperature, form, true);
  addOptionalField('top_p', topP, form, true);
  const { status, data, error } = await sendApiRequest('post', URL, form);
  handle400Statuses(status);
  logNetRequest(URL, status, data);

  if (status === 200) {
    const row = data.table[0];
    const cells = row.cells;
    const resultId = data.result_id;
    return {
      success: true,
      resultId,
    };
  } else {
    return {
      error: 'Something went wrong, please try again.',
    };
  }
};

export const useTableAnalysis = () => {
  const state = useSelector((state) => state.gridTableAnalysis);
  const { favoriteQuestions, prompts, selectedDocuments, tableRows } = state;

  return React.useMemo(() => {
    return {
      favoriteQuestions,
      prompts,
      selectedDocuments,
      tableRows,
    };
  }, [favoriteQuestions, prompts, selectedDocuments, tableRows]);
};
