import React from 'react';
import { Body7, Container } from '../../../../libs/nvstr-common-ui.es';
import styled from 'styled-components';
import { NODE_DEBUGGING } from '../index';

const HeaderWrapper = styled.div`
  padding: 2px 10px 4px 12px;
  padding-left: ${({ Left }) => Left || 12}px;
  padding-right: ${({ Right }) => Right || 10}px;

  background: ${({ theme }) => theme.themeColors.lowContrastBorder};
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;

  display: grid;
  grid-template-columns: 1fr 24px 24px;
`;
const NameWrapper = styled.div`
  padding-right: 16px;
`;
const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  svg {
    height: 18px;
    width: 18px;

    path {
      fill: ${({ theme }) => theme.themeColors.text};
    }
  }

  &:hover {
    svg {
      path {
        opacity: 0.7;
      }
    }
  }
`;
const dragHandleStyle = {
  display: 'inline-block',
};

export const NodeHeader = ({ name, id, actions, Left, Right }) => {
  const handleDeleteClick = () => {
    actions.onRemove();
  };
  const handleRemoveLinksClick = () => {
    actions.onRemoveAllLinks();
  };
  return (
    <HeaderWrapper Left={Left} Right={Right}>
      <span className="custom-drag-handle" style={dragHandleStyle}>
        <NameWrapper>
          {NODE_DEBUGGING ? (
            <Body7>
              {name}-{id}
            </Body7>
          ) : (
            <Container row verticallyCenter spaceBetween>
              <Body7>{name}</Body7>
            </Container>
          )}
        </NameWrapper>
      </span>

      {actions && 'onRemoveAllLinks' in actions ? (
        <IconWrapper onClick={handleRemoveLinksClick}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="M19,3H5A2,2 0 0,0 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5A2,2 0 0,0 19,3M19,19H5V5H19V19M11,16H10C8.39,16 6,14.94 6,12C6,9.07 8.39,8 10,8H11V10H10C9.54,10 8,10.17 8,12C8,13.9 9.67,14 10,14H11V16M14,16H13V14H14C14.46,14 16,13.83 16,12C16,10.1 14.33,10 14,10H13V8H14C15.61,8 18,9.07 18,12C18,14.94 15.61,16 14,16M15,13H9V11H15V13Z" />
          </svg>
        </IconWrapper>
      ) : (
        <div />
      )}

      {actions && 'onRemove' in actions && (
        <IconWrapper onClick={handleDeleteClick}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="M13.46,12L19,17.54V19H17.54L12,13.46L6.46,19H5V17.54L10.54,12L5,6.46V5H6.46L12,10.54L17.54,5H19V6.46L13.46,12Z" />
          </svg>
        </IconWrapper>
      )}
    </HeaderWrapper>
  );
};
