import React from 'react';
import { Body5, Container, H5, Page } from '../../../libs/nvstr-common-ui.es';
import { CloseButton } from '../../../components/buttons/CloseButton';
import ErrorText from '../../../components/UI/ErrorText';
import { ResultOnly } from '../components/AnswerLine';
import { Working } from '../../../components/UI/Working';
import { FlatButton, TransparentFlatButton } from '../../../components/buttons';
import styled from 'styled-components';
import { FooterSeparator } from '../../../components/layout/Body';

const Wrapper = styled.div`
  padding: 24px 0 0 0;
  overflow: auto;
`;
const ButtonWrap = styled.div`
  button {
    padding: 8px 0 !important;
  }
`;
const CloseButtonWrapper = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
`;
const Prompt = styled.textarea`
  outline: none;
  border: 1px solid ${({ theme }) => theme.themeColors.text};
  border-radius: 5px;
  font-size: 14px;
  line-height: 18px;
  color: ${({ theme }) => theme.themeColors.text};
  margin: 0;
  padding: 8px 16px;
  min-height: 100px;
  width: 100%;
`;

function Step({ data, index }) {
  const [showAll, setShowAll] = React.useState(false);
  if (!data) return null;
  return (
    <Container top={16}>
      <Container>
        <Container>
          <Body5 bold>{data.step_id}</Body5>
        </Container>
        {null && (
          <Container top={16}>
            <Body5>Step {index + 1}:</Body5>
          </Container>
        )}
      </Container>
      <Container top={8}>
        <Body5>{data.output}</Body5>
      </Container>
      <Container top={8}>
        {!showAll ? (
          <TransparentFlatButton onClick={() => setShowAll(true)}>Show All</TransparentFlatButton>
        ) : (
          <TransparentFlatButton onClick={() => setShowAll(false)}>Hide All</TransparentFlatButton>
        )}
        {showAll ? <Body5>{JSON.stringify(data)}</Body5> : null}
      </Container>
    </Container>
  );
}

function PlanSteps({ data }) {
  if (!data) return null;
  return (
    <Container>
      {data.map((step, i) => (
        <Step key={i} data={step} index={i} />
      ))}
    </Container>
  );
}

export const Output = ({
  agentName,
  executed,
  executedPlan,
  outputError: error,
  onExecute,
  result,
  prompt,
  onPromptChange,
  onCloseOutput,
}) => {
  const [showExecutedPlan, setShowExecutedPlan] = React.useState(false);

  React.useEffect(() => {
    if (executedPlan) console.log({ executedPlan });
  }, [executedPlan]);

  if (!agentName) {
    return null;
  }

  return (
    <Page>
      <Wrapper>
        <CloseButtonWrapper>
          <CloseButton onClick={onCloseOutput} />
        </CloseButtonWrapper>
        {executed ? (
          <>
            <Container>
              <Body5>
                Output for "{agentName}" - {prompt}
              </Body5>
            </Container>
            {error ? <ErrorText>{error}</ErrorText> : result ? <ResultOnly result={result} /> : <Working />}
            {executedPlan && showExecutedPlan ? (
              <>
                <FooterSeparator />
                <Container>
                  <Body5 bold>Executed Plan</Body5>
                  <Container>
                    <PlanSteps data={executedPlan?.plan_steps} />
                  </Container>
                </Container>
              </>
            ) : null}
            {executedPlan && !showExecutedPlan && (
              <ButtonWrap>
                <TransparentFlatButton onClick={() => setShowExecutedPlan(true)}>
                  View Executed Plan
                </TransparentFlatButton>
              </ButtonWrap>
            )}
          </>
        ) : (
          <Container>
            <Container bottom={24}>
              <H5>Run Agent - {agentName}</H5>
            </Container>
            <Body5>Prompt</Body5>
            <Container top={8}>
              <Prompt value={prompt} onChange={onPromptChange} />
            </Container>
            <Container top={24} style={{ textAlign: 'center' }}>
              <FlatButton fullWidth onClick={onExecute}>
                Execute
              </FlatButton>
            </Container>
          </Container>
        )}
      </Wrapper>
    </Page>
  );
};
