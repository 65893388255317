import React from 'react';
import { Body5, Container, H5, Page } from '../../libs/nvstr-common-ui.es';
import { DocumentIcon } from '../../assets/icons/svgs/DocumentIcon';
import { CloseButton } from '../../components/buttons/CloseButton';
import { useAdvancedSettings } from '../../hooks/application/useAdvancedSettings';
import { handle400Statuses, sendApiDownloadRequest, sendApiRequest } from '../../services/api';
import { logNetRequest } from '../../utils/usefulFuncs';
import BackArrowButton from '../../components/buttons/BackArrowButton';
import { Spinner } from '../../components/UI/LoadingSpinner';
import { SkeletonButton } from '../../components/buttons/SkeletonButton';
import { FlatButton } from '../../components/buttons';
import { FormState } from '../../components/UI/FormState';
import { FooterSeparator } from '../../components/layout/Body';
import { DOCUMENT_TEMPLATE_MODE_IDS } from '../../pages/DocumentCreatorPage';
import styled from 'styled-components';
import { DocumentTemplateAnswers } from './components/DocumentTemplateAnswers';
import { NewDocumentSelectionModal } from './components/DocumentSelectionModal';
import { AI_TOOLS, DOC_SELECT_MODES } from '../../constants';

const PageWrapper = styled.div`
  + div {
    width: 100%;
  }
`;
const SubmitButtonWrapper = styled.div`
  text-align: center;
  width: 100%;
`;
const FormWrapper = styled.div`
  input {
    border: 1px solid ${({ theme }) => theme.themeColors.border};
    color: ${({ theme }) => theme.themeColors.text};
    background-color: transparent;
    border-radius: 6px;
    width: 100%;
    padding: 0 !important;
  }

  select {
    border: 1px solid ${({ theme }) => theme.themeColors.border};
    color: ${({ theme }) => theme.themeColors.text};
    background-color: transparent;
    border-radius: 6px;
    width: 100%;
    padding: 12px 16px;
    -webkit-appearance: auto !important;
  }

  textarea {
    border: 1px solid ${({ theme }) => theme.themeColors.border};
    color: ${({ theme }) => theme.themeColors.text};
    background-color: transparent;
    border-radius: 6px;
    min-height: 100px;
    width: 100%;
    padding: 16px;
  }
`;
const SelectedDocumentWrapper = styled.div``;
const IconWrapper = styled.div`
  margin-right: 8px;

  svg {
    padding-top: 1px;
    height: 16px;
    width: 16px;

    path {
      fill: ${({ theme }) => theme.themeColors.text};
    }
  }
`;
const CloseButtonWrapper = styled.div`
  margin-left: 8px;

  svg {
    padding-top: 1px;
    height: 12px;
    width: 12px;

    path {
      fill: ${({ theme }) => theme.themeColors.text};
    }
  }
`;

const modeIdToUserModeConversion = (modeId) => {
  if (modeId === DOCUMENT_TEMPLATE_MODE_IDS.new_deal_triage) return AI_TOOLS.NEW_DEAL_TRIAGE;
  if (modeId === DOCUMENT_TEMPLATE_MODE_IDS.growth_equity_memo_template) return AI_TOOLS.DEAL_ANALYSIS;
  return null;
};
const generateHeadingText = (modeId) => {
  if (!modeId) return null;
  if (modeId === DOCUMENT_TEMPLATE_MODE_IDS.new_deal_triage) return 'New Deal Triage';
  if (modeId === DOCUMENT_TEMPLATE_MODE_IDS.growth_equity_memo_template) return 'Deal Analysis and Memo';
  return 'Mode missing name';
};

function SelectedDocument({ doc, handleRemove }) {
  if (!doc) return null;

  const { filename } = doc;
  return (
    <SelectedDocumentWrapper>
      <Container row verticallyCenter>
        <IconWrapper>
          <DocumentIcon />
        </IconWrapper>
        <Container>
          <Body5>{filename}</Body5>
        </Container>
        <CloseButtonWrapper>
          <CloseButton onClick={() => handleRemove(filename)} />
        </CloseButtonWrapper>
      </Container>
    </SelectedDocumentWrapper>
  );
}

const docSelectModes = [DOC_SELECT_MODES.companyTag];

export function DocumentTemplateTool({ onGoBack, modeId, tool }) {
  const { model } = useAdvancedSettings();

  const [isSelectingDocs, setIsSelectingDocs] = React.useState(false);
  const [selectedDocs, setSelectedDocs] = React.useState([]);
  const [streamComplete, setStreamComplete] = React.useState(false);

  const [isSubmittingToApi, setIsSubmittingToApi] = React.useState(false);
  const [formError, setFormError] = React.useState(null);

  const [resultId, setResultId] = React.useState(null);
  const [queryListMetadata, setQueryListMetadata] = React.useState(null);

  React.useEffect(() => {
    if (resultId) {
      const id = 'snapshot-answers-section';
      const yOffset = -26;
      const element = document.getElementById(id);
      if (element) {
        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

        setTimeout(() => {
          window.scrollTo({ top: y, behavior: 'smooth' });
        }, 300);
      }
    }
  }, [resultId]);

  const onSubmit = async () => {
    if (selectedDocs.length === 0) return;

    setFormError(null);
    setIsSubmittingToApi(true);

    setStreamComplete(false);
    setResultId(null);
    setQueryListMetadata(null);

    const URL = 'v1/genai_template_queries';
    const params = {
      mode_type: modeId,
      user_mode: modeIdToUserModeConversion(modeId),
      // ticker: company.symbol,
      doc_list: selectedDocs.map((d) => d.id),
      streaming: true,

      tool,
      model,
    };
    const { status, data } = await sendApiRequest('POST', URL, params);
    handle400Statuses(status);
    logNetRequest(URL, status, data);
    if (status === 200) {
      setResultId(data.id);
      const queries = data.template_queries;
      const questions = Object.values(queries);
      setQueryListMetadata(questions);
      setIsSubmittingToApi(false);
    } else {
      setFormError('Something went wrong, try again');
      setIsSubmittingToApi(false);
    }
  };

  const onDownloadDocument = async () => {
    try {
      const filename = 'GeneratedReport';
      const URL = `v1/genai_filled_template_doc/${modeId}/${resultId}`;
      const response = await sendApiDownloadRequest(URL);
      const { status, data, error } = response;
      logNetRequest(status, data, URL);
      if (status === 200) {
        const blob = await response.blob();
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        setFormError(error || 'Something went wrong downloading the file, please try again later.');
      }
    } catch (error) {
      setFormError(error || 'Something went wrong downloading the file, please try again later.');
      console.error('Error downloading the file:', error);
    }
  };

  const onClear = () => {
    setSelectedDocs([]);
    setResultId(null);
    setQueryListMetadata(null);
    setFormError(null);
    setStreamComplete(false);
  };

  const handleAddDocument = (v) => {
    if (Array.isArray(v)) {
      setSelectedDocs((prevState) => [...prevState, ...v]);
    } else {
      setSelectedDocs((prevState) => [...prevState, v]);
    }
  };

  const handleRemoveDocument = (filename) => {
    if (Array.isArray(filename)) {
      setSelectedDocs((prevState) => prevState.filter((sd) => !filename.map((d) => d.filename).includes(sd.filename)));
    } else {
      setSelectedDocs((prevState) => prevState.filter((sd) => sd.filename !== filename));
    }
  };

  const onSelectDocumentsClick = () => setIsSelectingDocs(true);

  const handleClearSnapshot = () => {
    onClear();
  };

  const onStreamComplete = () => {
    setStreamComplete(true);
  };

  return (
    <PageWrapper>
      <Page width={resultId ? '1800px' : '800px'}>
        <BackArrowButton onClick={onGoBack} />
        <Container top={40}>
          <H5>{generateHeadingText(modeId)}</H5>
          {selectedDocs.length === 0 && (
            <Container top={8}>
              <Body5>Select documents to get started.</Body5>
            </Container>
          )}
        </Container>
        {isSelectingDocs && (
          <NewDocumentSelectionModal
            docSelectModes={docSelectModes}
            defaultActiveMode={docSelectModes[0]}
            selectedDocs={selectedDocs}
            handleAddDocument={handleAddDocument}
            handleRemoveDocument={handleRemoveDocument}
            onDismiss={() => setIsSelectingDocs(false)}
          />
        )}
        {isSubmittingToApi ? (
          <Container top={80} bottom={80} centerAll>
            <Container>
              <Container centerAll>
                <Spinner />
              </Container>
              <Container top={24} left={12}>
                <Body5>Working...</Body5>
              </Container>
            </Container>
          </Container>
        ) : (
          <Container top={8}>
            <FormWrapper>
              {/*<AdvancedSettingsButton fields={advancedSettingsFields} />*/}
              <Container>
                {selectedDocs.map((d, i) => (
                  <Container key={d.id} top={0} bottom={8}>
                    <SelectedDocument doc={d} handleRemove={handleRemoveDocument} />
                  </Container>
                ))}
                <Container top={24}>
                  {selectedDocs.length > 0 ? (
                    <SkeletonButton onClick={onSelectDocumentsClick}>+ Select Documents</SkeletonButton>
                  ) : (
                    <FlatButton onClick={onSelectDocumentsClick}>+ Select Documents</FlatButton>
                  )}
                </Container>
              </Container>
              <Container top={48}>
                <Container centerAll>
                  <FormState error={formError} />
                </Container>
                <SubmitButtonWrapper>
                  <FlatButton disabled={selectedDocs.length === 0} onClick={onSubmit} fullWidth>
                    Create Report
                  </FlatButton>
                </SubmitButtonWrapper>
              </Container>
            </FormWrapper>
          </Container>
        )}
      </Page>
      {resultId && (
        <Page width={'1800px'}>
          <div id={'snapshot-answers-section'} />
          <DocumentTemplateAnswers
            queryListMetadata={queryListMetadata}
            resultId={resultId}
            selectedDocs={selectedDocs}
            modeId={modeId}
            onStreamComplete={onStreamComplete}
          />
          <FooterSeparator />
          <Container top={0} row verticallyCenter>
            <Container>
              <FlatButton
                disabled={!streamComplete}
                onClick={streamComplete ? onDownloadDocument : () => null}
                fullWidth
              >
                Download Document
              </FlatButton>
            </Container>
            <Container left={16}>
              <SkeletonButton onClick={handleClearSnapshot}>Close</SkeletonButton>
            </Container>
          </Container>
          {!streamComplete && (
            <Container top={8}>
              <Body5>Download available when report generation is complete</Body5>
            </Container>
          )}
        </Page>
      )}
    </PageWrapper>
  );
}
