import { useSelector } from 'react-redux';
import { CURRENT_DEFAULT_MODEL } from '../../constants';

export const AdvancedSettingsFields = {
  dailySummariesToggle: 'dailySummariesToggle',
  enableJS: 'enableJS',
  headlinesToggle: 'headlinesToggle',
  followUpQuestionsToggle: 'followUpQuestionsToggle',
  includeUserDocsToggle: 'includeUserDocsToggle',
  includeInferredMetadata: 'includeInferredMetadata',
  maxAnswerLength: 'maxAnswerLength',
  maxContextLength: 'maxContextLength',
  model: 'model',
  overrideQuestion: 'overrideQuestion',
  numOfPages: 'numOfPages',
  seeContext: 'seeContext',
  smoothingToggle: 'smoothingToggle',
  enableTextSearch: 'enableTextSearch',
  enableMath: 'enableMath',
  maxContextChunks: 'maxContextChunks',
  version: 'version',

  seed: 'seed',
  temperature: 'temperature',
  top_p: 'topP',
  useFYEContext: 'useFYEContext',
};

export function getAdvancedSettingDefaultValue(name) {
  const defaults = {
    [AdvancedSettingsFields.model]: CURRENT_DEFAULT_MODEL,
    [AdvancedSettingsFields.version]: 2,
    [AdvancedSettingsFields.maxAnswerLength]: '',
    [AdvancedSettingsFields.maxContextLength]: '',

    [AdvancedSettingsFields.smoothingToggle]: false,
    [AdvancedSettingsFields.enableJS]: true,
    [AdvancedSettingsFields.enableTextSearch]: false,
    [AdvancedSettingsFields.enableMath]: true,
    [AdvancedSettingsFields.includeInferredMetadata]: false,
    [AdvancedSettingsFields.useFYEContext]: false,
  };

  return defaults[name] || null;
}

export function toAdvancedSettingDisplayName(name) {
  switch (name) {
    case AdvancedSettingsFields.model:
      return 'Model';
    case AdvancedSettingsFields.seed:
      return 'Seed';
    case AdvancedSettingsFields.maxAnswerLength:
      return 'Max Answer Length';
    case AdvancedSettingsFields.maxContextLength:
      return 'Max Context Length';
    case AdvancedSettingsFields.maxContextChunks:
      return 'Max Context Chunks';
    case AdvancedSettingsFields.enableMath:
      return 'Enable Corrections';
    case AdvancedSettingsFields.includeInferredMetadata:
      return 'Include Metadata';
    case AdvancedSettingsFields.smoothingToggle:
      return 'Enable Smoothing';
    case AdvancedSettingsFields.temperature:
      return 'Temperature';
    case AdvancedSettingsFields.seeContext:
      return 'Show Context';
    case AdvancedSettingsFields.top_p:
      return 'Top P';
    case AdvancedSettingsFields.version:
      return 'Version';
    case AdvancedSettingsFields.enableJS:
      return 'Enable JS';
    case AdvancedSettingsFields.enableTextSearch:
      return 'Enable Text Search';
    case AdvancedSettingsFields.useFYEContext:
      return 'Use Fiscal Year End Context';

    default:
      return '';
  }
}

export function useAdvancedSettingsFields() {
  return useSelector((state) => state.application.advancedSettingsFields);
}

export function useShowAdvancedSettings() {
  const fields = useAdvancedSettingsFields();
  return fields?.length > 0;
}

export function useAdvancedSettings() {
  const state = useSelector((state) => state.application.advancedSettings);
  return state;
}
