import React from 'react';
import styled from 'styled-components';
import { Header } from '../components/navigation';
import { Body } from '../components/layout/Body';
import { useNavigate } from 'react-router';
import { scrollToTop } from '../utils/usefulFuncs';
import { AdvancedSettingsFields } from '../hooks/application/useAdvancedSettings';
import { AI_TOOLS, CURRENT_DEFAULT_MODEL } from '../constants';
import { useDispatch } from 'react-redux';
import { applicationActionTypes } from '../constants/actionTypes';
import { ROUTES } from '../constants/routes';
import { DocumentTemplateTool } from '../containers/DigitalAnalyst/DocumentTemplateTool';

const PageWrapper = styled.div`
  + div {
    width: 100%;
  }
`;

const advancedSettingsFields = [
  AdvancedSettingsFields.model,
  AdvancedSettingsFields.maxContextLength,
  AdvancedSettingsFields.maxContextChunks,
];

export const DOCUMENT_TEMPLATE_MODE_IDS = {
  new_deal_triage: 'new_deal_triage',
  growth_equity_memo_template: 'growth_equity_memo_template',
};

const convertModeIdToTool = (modeId) => {
  if (modeId === 42) return AI_TOOLS.NEW_DEAL_TRIAGE;
  return null;
};

const defaultAdvancedSettings = {
  model: CURRENT_DEFAULT_MODEL,
  // maxContextLength: '',
  // maxAnswerLength: '',
};

function DocumentCreatorPage({ modeId }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onGoBack = () => navigate(ROUTES.home);

  React.useEffect(() => {
    dispatch({
      type: applicationActionTypes.setAdvancedSettings,
      payload: defaultAdvancedSettings,
    });
  }, []);

  React.useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <PageWrapper>
      <Header />
      <Body withFooter>
        <DocumentTemplateTool onGoBack={onGoBack} modeId={modeId} tool={convertModeIdToTool(modeId)} />
      </Body>
    </PageWrapper>
  );
}

export default DocumentCreatorPage;
