import React from 'react';
import { NodeHeader } from './components/NodeHeader';
import { NodeContentWrapper } from './styles';
import { Working } from '../../../components/UI/Working';
import { Answer } from '../../DigitalAnalyst/components/AnswerLine';
import { AI_TOOLS } from '../../../constants';
import { Body5, Body7, Container } from '../../../libs/nvstr-common-ui.es';
import styled from 'styled-components';
import { NodeDebugger } from './components/NodeDebugger';
import ExportDataHandle from './components/handles/ExportDataHandle';
import { NODE_HANDLE_IDS } from './index';
import { NodeWrapper } from './components/NodeWrapper';

const ContentWrapper = styled(NodeContentWrapper)`
  min-width: 700px;
  min-height: 320px;
`;
const CompanyAnswerWrapper = styled.div`
  padding-top: 12px;
  border-bottom: 1px solid ${({ theme }) => theme.themeColors.lowContrastBorder};
`;

const Content = (props) => {
  const { id, data, value, onTextChange } = props;
  const { answers, error, isComplete } = data;

  if (error) {
    return (
      <ContentWrapper>
        <Container bottom={8}>
          <Body5>Something went wrong.</Body5>
        </Container>
        <Body5>{error}</Body5>
      </ContentWrapper>
    );
  }
  if (!!answers && Object.keys(answers).length === 0) {
    return (
      <ContentWrapper>
        <Container>
          <Body7>{value}</Body7>
        </Container>
        <Working />
      </ContentWrapper>
    );
  }
  if (answers) {
    const companySymbols = Object.keys(answers);
    return (
      <ContentWrapper>
        <Container bottom={16}>
          <Body5>Question Asked: {value}</Body5>
        </Container>
        {companySymbols.map((symbol, index) => (
          <CompanyAnswerWrapper key={index}>
            <Container bottom={0}>
              <Body5>{symbol}:</Body5>
            </Container>
            <Answer result={answers[symbol]} userMode={AI_TOOLS.QUERY_ENGINE} isComplete={isComplete} />
          </CompanyAnswerWrapper>
        ))}
      </ContentWrapper>
    );
  }
  return (
    <ContentWrapper>
      <textarea placeholder="Enter your question" value={value} onChange={onTextChange} />
    </ContentWrapper>
  );
};

export default React.memo((props) => {
  const { data, id } = props;
  const start = ' what is their technology spend?';
  const [value, setValue] = React.useState('');

  React.useEffect(() => {
    data.actions.updateData({ question: value });
  }, [value]);

  const onTextChange = (e) => {
    setValue(e.target.value);
  };
  const name = 'Question';
  return (
    <NodeWrapper data-id={id} name={name} data={data}>
      <NodeHeader id={id} name={name} actions={data.actions} Left={8} />
      <Content id={id} data={data} value={value} onTextChange={onTextChange} />
      <ExportDataHandle {...props} handleActionId={NODE_HANDLE_IDS.ForEachAction} />

      <NodeDebugger id={id} />
    </NodeWrapper>
  );
});
