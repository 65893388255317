import React from 'react';
import styled from 'styled-components';
import { Scatter } from 'react-chartjs-2';
import { Body5 } from '../../libs/nvstr-common-ui.es';
import { calcSuggestedMinMax } from '../../utils/usefulFuncs';
import { useColorTheme } from '../../hooks';
import 'chartjs-adapter-date-fns';
import { TransparentFlatButton } from '../../components/buttons';

const ChartHeader = styled.div`
  padding: 0 0 16px 48px;
`;
const ChartAnalysisGlobalWrapper = styled.div``;
const ChartAnalysisWrapper = styled.div`
  overflow: auto;
`;
const ChartWrapper = styled.div`
  height: ${({ TimeOnXAxis }) => (TimeOnXAxis ? '660px' : '460px')};
  padding: 0 0 10px 0;

  canvas {
    display: block;
    height: ${({ TimeOnXAxis }) => (TimeOnXAxis ? '660px' : '460px')};
  }
`;
const ChartExtraButtons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0 0 12px 32px;

  button {
    padding: 4px 18px;
  }
`;

const extractDataValuesFromDatasets = (datasets) => {
  let points = [];
  datasets.forEach((dataset) => {
    const { data } = dataset;
    points = [...points, ...data];
  });
  return points;
};

export const SpeakerByDocSentimentTimeChart = ({ data, activeKey }) => {
  const colorTheme = useColorTheme();

  const [hiddenIndexes, setHiddenIndexes] = React.useState([]);

  const generateOptions = (data, colorTheme) => {
    if (!data) return null;

    const { min, max } = calcSuggestedMinMax(data, 1);
    const minDate = new Date(Math.min(...extractDataValuesFromDatasets(data.datasets).map((point) => point.x)));
    const firstDayOfYearMinDate = new Date(minDate.getFullYear(), 0, 1);
    return {
      maintainAspectRatio: false,

      elements: {
        point: {
          radius: 4,
          hoverRadius: 12,
          pointStyle: 'circle',
        },
        line: {
          borderWidth: 3,
        },
      },

      options: {
        responsive: true,
        maintainAspectRatio: false,
      },

      plugins: {
        tooltip: {
          enabled: true,
          callbacks: {
            label: function (context) {
              const { parsed, raw } = context;
              const xValue = parsed.x;
              const yValue = parsed.y;
              const formattedDate = new Date(xValue).toLocaleDateString();
              const filename = raw.doc.filename;
              const maxLength = 70;
              const displayFilename = filename.slice(0, maxLength) + (filename.length >= maxLength ? '...' : '');
              return `${formattedDate} - Value: ${yValue} - ${displayFilename}`;
            },
          },
        },
        legend: {
          display: true,
          onClick: (event, legendItem) => {
            const datasetIndex = legendItem.datasetIndex;
            setHiddenIndexes((prev) =>
              prev.includes(datasetIndex) ? prev.filter((v) => v !== datasetIndex) : [...prev, datasetIndex]
            );
          },
        },
        title: {
          display: false,
        },
      },
      scales: {
        y: {
          beginAtZero: false,
          min,
          max,
          ticks: {
            min,
            max,
            stepSize: 0.5,
            color: colorTheme.text,
          },
          title: {
            display: true,
            text: 'Dovish - Neutral - Hawkish',
          },
          grid: {
            color: (context) => (context.tick.major ? colorTheme.border : colorTheme.lowContrastBorder), //  '#aaa' '#fafafa'
          },
        },
        x: {
          type: 'time', // This tells Chart.js to parse x values as dates.
          time: {
            unit: 'day',
            displayFormats: {
              day: 'MMM dd, yyyy', // e.g., "Jan 01, 2023"
            },
          },
          beginAtZero: false,
          grid: {
            tickWidth: 1,
            lineWidth: 1,
            color: (context) => (context.tick.major ? colorTheme.border : colorTheme.lowContrastBorder), //  '#aaa' '#fafafa'
          },
          border: {
            display: false,
          },
          ticks: {
            display: true,
            color: colorTheme.text,
            font: {
              family: 'Bolton, Arial',
              size: 12,
              color: colorTheme.text,
            },
            autoSkip: false,
          },
          min: firstDayOfYearMinDate,
        },
      },
    };
  };

  const [options, setOptions] = React.useState(generateOptions(data, colorTheme));
  const [chartData, setChartData] = React.useState(null);
  React.useEffect(() => {
    setOptions(generateOptions(data, colorTheme));
  }, [data, colorTheme]);

  React.useEffect(() => {
    setChartData({
      labels: data.labels,
      datasets: data.datasets.map((dataset, i) => ({
        ...dataset,
        hidden: hiddenIndexes.includes(i),
      })),
      tension: 0.1,
    });
  }, [data, hiddenIndexes, activeKey, colorTheme]);

  const handleSelectAll = () => {
    const nextIndexes = [];
    setHiddenIndexes(nextIndexes);
    setChartData({
      labels: data.labels,
      datasets: data.datasets.map((dataset, i) => ({
        ...dataset,
        hidden: nextIndexes.includes(i),
      })),
      tension: 0.1,
    });
  };

  const handleUnselectAll = () => {
    function fillArrayWithIndexes(length) {
      return Array.from({ length }, (_, index) => index);
    }

    const datasetCount = data.datasets.length;
    const nextIndexes = fillArrayWithIndexes(datasetCount);
    setHiddenIndexes(nextIndexes);
    setChartData({
      labels: data.labels,
      datasets: data.datasets.map((dataset, i) => ({
        ...dataset,
        hidden: nextIndexes.includes(i),
      })),
      tension: 0.1,
    });
  };

  if (!chartData) {
    return null;
  }

  return (
    <ChartAnalysisGlobalWrapper>
      <ChartAnalysisWrapper className={'chart-wrapper'}>
        <ChartHeader>
          <Body5 bold>{`Doc Sentiment Over Time (${activeKey})`}</Body5>
        </ChartHeader>
        <ChartExtraButtons>
          <TransparentFlatButton onClick={handleSelectAll}>Select All Speakers</TransparentFlatButton>
          <TransparentFlatButton onClick={handleUnselectAll}>Unselect All Speakers</TransparentFlatButton>
        </ChartExtraButtons>
        <ChartWrapper DataPoints={data.labels.length}>
          <Scatter data={chartData} options={options} />
        </ChartWrapper>
      </ChartAnalysisWrapper>
    </ChartAnalysisGlobalWrapper>
  );
};
