import React from 'react';
import { Body5, Body7, Container } from '../../libs/nvstr-common-ui.es';
import styled from 'styled-components';
import { sendApiRequest } from '../../services/api';
import { logNetRequest } from '../../utils/usefulFuncs';
import { convertHexToRGBA, createTimeInstance, formatLocalizedDateTime } from '../../libs/nvstr-utils.es';
import DatePicker from 'react-date-picker';
import { FooterSeparator } from '../../components/layout/Body';
import { FlatButton, TransparentFlatButton } from '../../components/buttons';
import { FED_SPEAKERS } from '../../constants/fedSpeakers';

const Wrapper = styled.div`
  input {
    outline: none;
    font-size: 14px;
    line-height: 18px;
    margin: 0;
    width: 100%;
    max-width: 480px;

    border: 1px solid ${({ theme }) => theme.themeColors.border};
    color: ${({ theme }) => theme.themeColors.text};
    background-color: transparent;
    border-radius: 6px;
    padding: 8px;
  }

  select {
    border: 1px solid ${({ theme }) => theme.themeColors.border};
    color: ${({ theme }) => theme.themeColors.text};
    background-color: transparent;
    border-radius: 6px;
    width: 100%;
    max-width: 576px;
    padding: 16px;
    -webkit-appearance: auto !important;
  }
`;
const ActionButtonWrapper = styled.div`
  padding: 0 0 24px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
`;
const DatePickerWrapper = styled.div`
  height: 48px;
  padding: 16px 0 0 0;

  .react-date-picker__inputGroup__input {
    border: none;
    padding: 0 8px;
  }

  .react-date-picker__inputGroup__input {
    &.react-date-picker__inputGroup__year {
      min-width: 56px;
    }

    min-width: 40px;
    text-align: center;
  }

  .react-date-picker__wrapper {
    border: 1px solid ${({ theme }) => theme.themeColors.border};
    border-radius: 4px;
    padding: 8px;
  }

  .react-calendar {
    background: ${({ theme }) => theme.themeColors.appBackground};

    color: ${({ theme }) => theme.themeColors.text};

    .react-calendar__tile--now {
      border: 1px solid ${({ theme }) => theme.themeColors.primaryCtaButton};
      background: ${({ theme }) => theme.themeColors.appBackground};
      color: ${({ theme }) => theme.themeColors.buttonText};
    }

    button {
      background: ${({ theme }) => theme.themeColors.appBackground} !important;
      color: ${({ theme }) => theme.themeColors.text} !important;

      &:disabled {
        cursor: not-allowed;
      }

      &:enabled:hover {
        border-radius: 2px;
        background: ${({ theme }) => theme.themeColors.primaryCtaButton} !important;
        color: ${({ theme }) => theme.themeColors.buttonText} !important;

        * {
          color: ${({ theme }) => theme.themeColors.buttonText} !important;
        }
      }
    }
  }

  .react-calendar__navigation button:disabled {
    background: ${({ theme }) => theme.themeColors.appBackground};
    color: ${({ theme }) => theme.themeColors.text};
  }

  .react-calendar__tile--active {
    background: ${({ theme }) => theme.themeColors.primaryCtaButton};
    color: ${({ theme }) => theme.themeColors.buttonText};
  }

  .react-calendar__tile:disabled {
    background: ${({ theme }) => theme.themeColors.border};
    color: ${({ theme }) => theme.themeColors.text};
  }
`;
const CalendarIconWrapper = styled.div`
  svg {
    height: 22px;
    width: 22px;

    fill: ${({ theme }) => theme.themeColors.text};
  }
`;

function OpenCalenderIcon() {
  return (
    <CalendarIconWrapper>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <path d="M14,3V5H17.59L7.76,14.83L9.17,16.24L19,6.41V10H21V3M19,19H5V5H12V3H5C3.89,3 3,3.9 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V12H19V19Z" />
      </svg>
    </CalendarIconWrapper>
  );
}

function ClearIcon() {
  return (
    <CalendarIconWrapper>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <path d="M19,15.59L17.59,17L14,13.41L10.41,17L9,15.59L12.59,12L9,8.41L10.41,7L14,10.59L17.59,7L19,8.41L15.41,12L19,15.59M22,3A2,2 0 0,1 24,5V19A2,2 0 0,1 22,21H7C6.31,21 5.77,20.64 5.41,20.11L0,12L5.41,3.88C5.77,3.35 6.31,3 7,3H22M22,5H7L2.28,12L7,19H22V5Z" />
      </svg>
    </CalendarIconWrapper>
  );
}

const defaultStartDate = createTimeInstance().subtract(6, 'months').toDate();
const defaultEndDate = createTimeInstance().subtract(0, 'days').toDate();
const maxDate = createTimeInstance().subtract(0, 'days').toDate();

let textEntryCache = '';
let startDateCache = defaultStartDate;
let endDateCache = defaultEndDate;

const useSpeakerSearch = () => {
  const [results, setResults] = React.useState(null);

  const getResults = React.useCallback(async (params) => {
    const { speaker, startDate, endDate } = params;
    const dateFormat = '-L';
    const startDateApi = formatLocalizedDateTime(dateFormat, startDate);
    const endDateApi = formatLocalizedDateTime(dateFormat, endDate);
    const URL = `v1/genai_doc_search?speaker_query=${speaker}&start_date=${startDateApi}&end_date=${endDateApi}&fed_only=true`;
    const { status, data } = await sendApiRequest('get', URL);
    logNetRequest(status, data);
    if (status === 200) {
      setResults(data);
    }
  }, []);

  return { results, getResults };
};

const SelectableDocumentsRowWrapper = styled.div`
  display: grid;
  grid-template-columns: max-content;
  width: max-content;
`;
const ResultWrapper = styled.div`
  display: inline-block;

  &:last-child {
    margin-bottom: 0px;
  }

  margin-bottom: 8px;
  margin-right: 16px;

  padding: 12px 16px;
  background: ${({ theme }) => convertHexToRGBA(theme.themeColors.lowContrastBorder, 1)};
  border: 1px solid ${({ theme }) => theme.themeColors.lowContrastBorder};
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.14s;

  &:hover {
    background: ${({ theme }) => convertHexToRGBA(theme.themeColors.primaryCtaButton, 0.05)};
    border: 1px solid ${({ theme }) => convertHexToRGBA(theme.themeColors.primaryCtaButton, 0.2)};

    color: ${({ theme }) => theme.themeColors.primaryCtaButton} !important;
  }

  &.selected {
    border: 1px solid ${({ theme }) => theme.themeColors.primaryCtaButton};
    background: ${({ theme }) => convertHexToRGBA(theme.themeColors.primaryCtaButton, 0.2)};

    color: ${({ theme }) => theme.themeColors.buttonText} !important;
  }

  svg {
    height: 18px;
    width: 18px;
    margin-right: 8px;
    margin-left: -4px;
  }
`;
const Result = ({ data, onSelect, onRemoveSelect, selectedDocs }) => {
  const { id, filename } = data;
  const isSelected = selectedDocs?.filter((d) => d.id === id).length > 0;
  const handleClick = () => {
    isSelected ? onRemoveSelect(data.filename) : onSelect(data);
  };

  return (
    <ResultWrapper className={isSelected ? 'selected' : ''} onClick={handleClick}>
      <Body5>{filename}</Body5>
    </ResultWrapper>
  );
};

export const SpeakerDocumentSelection = ({ onSelect, onRemoveSelect, selectedDocs }) => {
  const [textEntry, setTextEntry] = React.useState(textEntryCache);
  const [startDate, setStartDate] = React.useState(startDateCache);
  const [endDate, setEndDate] = React.useState(endDateCache);

  const { results, getResults } = useSpeakerSearch();

  const onSpeakerChange = (e) => {
    const v = e.target.value;
    textEntryCache = v;
    setTextEntry(v);
  };

  const handleSelectAll = () => {
    onSelect(results);
  };
  const handleUnselectAll = () => {
    onRemoveSelect(results);
  };

  const handleStartDateChange = (value) => {
    if (value === null) {
      setStartDate(defaultStartDate);
    } else {
      startDateCache = value;
      setStartDate(value);
    }
  };
  const handleEndDateChange = (value) => {
    if (value === null) {
      setEndDate(defaultEndDate);
    } else {
      endDateCache = value;
      setEndDate(value);
    }
  };

  const onSearchClick = () => {
    getResults({
      speaker: textEntry,
      startDate: createTimeInstance(startDate),
      endDate: createTimeInstance(endDate),
    });
  };

  return (
    <Wrapper>
      <Container bottom={36}>
        <Body5>Select a speaker and/or date range to search for matching source documents.</Body5>
      </Container>
      <Container left={2}>
        <Body5>Speaker Name</Body5>
      </Container>
      <Container top={8}>
        <select value={textEntry} onChange={onSpeakerChange}>
          <option value={''}>Please select...</option>
          {FED_SPEAKERS.map((n, i) => (
            <option key={n}>{n}</option>
          ))}
        </select>
      </Container>
      <Container row>
        <Container>
          <Container row verticallyCenter top={24}>
            <Container top={8}>
              <Body7>From: </Body7>
            </Container>
          </Container>
          <Container>
            <DatePickerWrapper>
              <DatePicker
                name={'start-date'}
                calendarIcon={OpenCalenderIcon}
                clearIcon={ClearIcon}
                onChange={handleStartDateChange}
                value={startDate}
                // maxDate={maxDate}
              />
            </DatePickerWrapper>
          </Container>
        </Container>
        <Container left={16}>
          <Container row verticallyCenter top={24}>
            <Container top={8}>
              <Body7>To: </Body7>
            </Container>
          </Container>
          <Container>
            <DatePickerWrapper>
              <DatePicker
                name={'end-date'}
                calendarIcon={OpenCalenderIcon}
                clearIcon={ClearIcon}
                onChange={handleEndDateChange}
                value={endDate}
                // maxDate={maxDate}
              />
            </DatePickerWrapper>
          </Container>
        </Container>
      </Container>
      <Container top={52}>
        <FlatButton onClick={onSearchClick}>Search</FlatButton>
      </Container>
      <FooterSeparator />
      <Container>
        {results === null ? null : results.length === 0 ? (
          <Container>
            <Body5>No documents match the filter criteria</Body5>
          </Container>
        ) : (
          <Container>
            <ActionButtonWrapper>
              <TransparentFlatButton onClick={handleSelectAll}>Select All</TransparentFlatButton>
              <TransparentFlatButton onClick={handleUnselectAll}>Unselect All</TransparentFlatButton>
            </ActionButtonWrapper>
            <SelectableDocumentsRowWrapper>
              {results.map((r, i) => (
                <Result
                  key={i}
                  data={r}
                  onSelect={onSelect}
                  onRemoveSelect={onRemoveSelect}
                  selectedDocs={selectedDocs}
                />
              ))}
            </SelectableDocumentsRowWrapper>
          </Container>
        )}
      </Container>
    </Wrapper>
  );
};
