import { DOCUMENT_TEMPLATE_MODE_IDS } from '../../../pages/DocumentCreatorPage';
import { NewDealTriage } from '../../../components/documentTemplates/NewDealTriage';
import { GrowthEquityMemo } from '../../../components/documentTemplates/GrowthEquityMemo';
import React from 'react';
import { Body5, Container } from '../../../libs/nvstr-common-ui.es';
import { Working } from '../../../components/UI/Working';
import { useDocumentTemplateAnswers } from '../../../hooks/features/useDocumentTemplateAnswers';

function Template({ modeId, answers, selectedDocs }) {
  if (!modeId) return null;
  if (modeId === DOCUMENT_TEMPLATE_MODE_IDS.new_deal_triage)
    return <NewDealTriage answers={answers} selectedDocs={selectedDocs} />;
  if (modeId === DOCUMENT_TEMPLATE_MODE_IDS.growth_equity_memo_template)
    return <GrowthEquityMemo answers={answers} selectedDocs={selectedDocs} />;
}

export function DocumentTemplateAnswers({ modeId, resultId, selectedDocs, queryListMetadata, onStreamComplete }) {
  const {
    answersLookup,
    error: streamingError,
    streamEnd: streamComplete,
  } = useDocumentTemplateAnswers(resultId, queryListMetadata);

  React.useEffect(() => {
    if (streamComplete) {
      onStreamComplete();
    }
  }, [streamComplete]);

  if (!answersLookup || Object.keys(answersLookup).length === 0) {
    return (
      <Container top={100} bottom={200}>
        <Working />
      </Container>
    );
  }

  return (
    <Container>
      {streamingError && (
        <Container bottom={24}>
          <Body5>An unexpected error occurred. Please try again.</Body5>
        </Container>
      )}
      <Template modeId={modeId} answers={answersLookup} selectedDocs={selectedDocs} />
    </Container>
  );
}
