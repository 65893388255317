import { useNavigate } from 'react-router';
import React from 'react';
import { ROUTES } from '../../../constants/routes';
import { handle400Statuses, sendApiRequest } from '../../../services/api';
import { logNetRequest } from '../../../utils/usefulFuncs';
import { Body1, Body5, Container, H5, Page } from '../../../libs/nvstr-common-ui.es';
import BackArrowButton from '../../../components/buttons/BackArrowButton';
import { JsonEditor } from 'json-edit-react';
import { FlatButton, TransparentFlatButton } from '../../../components/buttons';
import ErrorText from '../../../components/UI/ErrorText';
import { SkeletonButton } from '../../../components/buttons/SkeletonButton';
import styled from 'styled-components';
import { AllAgents } from './AllAgents';
import { Modal } from '../../../components/layout/Modal';
import { CloseButton } from '../../../components/buttons/CloseButton';
import { showErrorBanner } from '../../../utils/application';
import { useDispatch } from 'react-redux';

const ButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;

  justify-content: center;

  button {
    width: 120px;
  }
`;
const PanelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  //gap: 24px;
`;
const SaveAsInput = styled.input`
  outline: none;
  border: 1px solid ${({ theme }) => theme.themeColors.text};
  border-radius: 5px;
  font-size: 14px;
  line-height: 18px;
  color: ${({ theme }) => theme.themeColors.text};
  margin: 0;
  padding: 8px 16px;
  width: 360px;
`;
const TextField = styled.input`
  outline: none;
  border: 1px solid ${({ theme }) => theme.themeColors.text};
  border-radius: 5px;
  font-size: 14px;
  line-height: 18px;
  color: ${({ theme }) => theme.themeColors.text};
  margin: 0;
  padding: 8px 16px;
  width: 260px;
`;
const ActionWrapper = styled.div`
  padding: 24px 0 0 0;
  display: flex;
  flex-direction: row;
  gap: 16px;
`;
const FooterActionWrapper = styled.div`
  border-top: 1px solid ${({ theme }) => theme.themeColors.lowContrastBorder};
  margin: 24px 0 0 0;
  padding: 24px 0 8px 0;
  display: flex;
  flex-direction: row;
  gap: 16px;
`;

export const Editor = ({ onRun }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [modalProps] = React.useState({ maximizeWidth: false, forceHeight: false });

  const [agentIdValue, setAgentIdValue] = React.useState('');
  const [agentName, setAgentName] = React.useState(null);
  const [agentId, setAgentId] = React.useState(null);
  const [jsonData, setJsonData] = React.useState({});
  const [revertJsonData, setRevertJsonData] = React.useState({});
  const [error, setError] = React.useState(null);

  const [showEditMode, setShowEditMode] = React.useState(false);
  const [showAllAgents, setShowAllAgents] = React.useState(false);

  const [showSaveAsModal, setShowSaveAsModal] = React.useState(false);
  const [saveAsName, setSaveAsName] = React.useState('');
  const [showSaveAsError, setShowSaveAsError] = React.useState(null);

  const resetState = () => {
    setShowEditMode(false);
    setError(null);
    setJsonData({});
    setAgentId(null);
    setAgentName(null);
    setAgentIdValue('');
  };
  const onGoBack = () => navigate(ROUTES.home);
  const onGoBackEditMode = () => resetState();

  const getAgentDefinition = async (id) => {
    const URL = `v1/genai_agent_script_definitions/${id}`;
    const { status, data } = await sendApiRequest('get', URL);
    logNetRequest(status, data);
    handle400Statuses(status);
    if (status === 200) {
      const { id, script_name: name, definition_json } = data;
      setAgentName(name);
      setAgentId(id);
      const json = JSON.parse(definition_json);
      setJsonData(json);
      setRevertJsonData(json);
      return true;
    } else {
      setError('Something went wrong, double check agent exists and try again.');
      return false;
    }
  };

  const updateAgentDefinitionOnServer = async (id, jsonData) => {
    const URL = `v1/genai_agent_script_definitions/${id}`;
    const form = {
      id,
      definition_json: JSON.stringify(jsonData),
      script_name: agentName,
    };
    const { status, data } = await sendApiRequest('patch', URL, form);
    logNetRequest(status, data);
    handle400Statuses(status);
    if (status === 200) {
      return true;
    } else {
      showErrorBanner('Something went wrong, please try again.', null, dispatch);
      return false;
    }
  };

  const sendAgentDefinitionToServer = async (name, jsonData) => {
    const URL = `v1/genai_agent_script_definitions`;
    const form = {
      definition_json: JSON.stringify(jsonData),
      script_name: name,
    };
    const { status, data } = await sendApiRequest('post', URL, form);
    logNetRequest(status, data);
    handle400Statuses(status);
    if (status === 200) {
      return { success: true, newId: data.id };
    } else {
      showErrorBanner('Something went wrong, please try again.', null, dispatch);
      return false;
    }
  };

  const onAgentValueChange = (e) => {
    setAgentIdValue(e.target.value);
  };

  const onGetAllAgents = () => {
    setShowAllAgents(true);
  };

  const onJSONValueChange = (v) => {
    setJsonData(v);
  };

  const onEditClick = () => {
    onLoadAgent(agentIdValue);
  };

  const onSaveAsNameChange = (e) => {
    setSaveAsName(e.target.value);
  };

  const onLoadAgent = async (id) => {
    if (id === '' || !id) {
      setError('Enter an agent id');
      return;
    }
    setError(null);
    const success = await getAgentDefinition(id);
    if (success) {
      setShowEditMode(true);
    }
  };

  const onSave = async (id, jsonData) => {
    const success = await updateAgentDefinitionOnServer(id, jsonData);
  };

  const onSaveAsClick = async () => {
    setShowSaveAsModal(true);
    setShowSaveAsError(null);
  };

  const onRevertAndRun = async (id) => {
    setJsonData(revertJsonData);
    const success = await updateAgentDefinitionOnServer(id, revertJsonData);
    if (success) {
      onRunClick();
    }
  };

  const onSaveAndRun = async (id) => {
    const success = await updateAgentDefinitionOnServer(id, jsonData);
    if (success) {
      onRunClick();
    }
  };

  const onSaveRevertVersion = (id, jsonData) => {
    setRevertJsonData(jsonData);
    onSave(id, jsonData);
  };

  const onRunClick = () => {
    onRun(agentName);
  };

  const onSaveAs = async (saveAsName, jsonData) => {
    setShowSaveAsError(null);
    const { success, newId } = await sendAgentDefinitionToServer(saveAsName, jsonData);
    if (success) {
      setAgentId(newId);
      setAgentName(saveAsName);
      setShowSaveAsModal(false);
      setSaveAsName('');
    } else {
      setShowSaveAsError('Something went wrong, try again.');
    }
  };

  const onCancelSaveAs = () => {
    setShowSaveAsModal(false);
    setSaveAsName('');
  };

  if (showEditMode) {
    return (
      <Page>
        {showSaveAsModal && (
          <Modal onDismiss={() => setShowSaveAsModal(false)} modalProps={modalProps}>
            <Container row verticallyCenter spaceBetween>
              <Container bottom={4}>
                <Body1 bold>Enter Agent Script Name</Body1>
              </Container>
              <CloseButton onClick={() => setShowSaveAsModal(false)} />
            </Container>
            <Container top={24}>
              <SaveAsInput value={saveAsName} onChange={onSaveAsNameChange} />
            </Container>
            <Container top={24}>
              {showSaveAsError && (
                <Container bottom={16}>
                  <ErrorText>{showSaveAsError}</ErrorText>
                </Container>
              )}
              <ButtonRow>
                <TransparentFlatButton onClick={onCancelSaveAs}>Cancel</TransparentFlatButton>
                <FlatButton onClick={() => onSaveAs(saveAsName, jsonData)}>Save</FlatButton>
              </ButtonRow>
            </Container>
          </Modal>
        )}
        <BackArrowButton onClick={onGoBackEditMode} />
        <Container top={48}>
          <Body5 bold>Agent Name</Body5>
        </Container>
        <Container top={8}>
          <Body5 uppercase>{agentName}</Body5>
        </Container>
        <Container top={24}>
          <Body5 bold>Agent ID</Body5>
        </Container>
        <Container top={8}>
          <Body5 uppercase>{agentId}</Body5>
        </Container>
        <Container top={24}>
          <Body5 bold>Definition</Body5>
        </Container>
        <Container top={8}>
          <JsonEditor data={jsonData} setData={onJSONValueChange} />
        </Container>
        <Container top={24} bottom={8}>
          <Container style={{ textAlign: 'center' }} row>
            <Container left={16}>
              <TransparentFlatButton onClick={() => onRevertAndRun(agentId)}>Revert Save And Run</TransparentFlatButton>
            </Container>
            <Container left={16}>
              <SkeletonButton onClick={() => onSaveRevertVersion(agentId, jsonData)} fullWidth>
                Overwrite Revert Version
              </SkeletonButton>
            </Container>
            <Container left={16}>
              <SkeletonButton onClick={() => onSaveAsClick(agentId, jsonData)} fullWidth>
                Save As
              </SkeletonButton>
            </Container>
            <Container left={16}>
              <FlatButton onClick={() => onSaveAndRun(agentId, jsonData)} fullWidth>
                Save And Run
              </FlatButton>
            </Container>
          </Container>
        </Container>
      </Page>
    );
  }
  return (
    <PanelWrapper>
      <Page>
        <BackArrowButton onClick={onGoBack} />
        <Container top={36}>
          <H5 bold>Agent Script Editor</H5>
        </Container>
        <Container top={24}>
          <Body5>Agent ID</Body5>
        </Container>
        <Container top={8}>
          <TextField value={agentIdValue} onChange={onAgentValueChange} />
        </Container>
        {error && (
          <Container top={8}>
            <ErrorText>{error}</ErrorText>
          </Container>
        )}
        <ActionWrapper>
          <Container>
            <SkeletonButton onClick={onEditClick}>Load Definition</SkeletonButton>
          </Container>
          <Container>
            <FlatButton onClick={onRunClick}>Run</FlatButton>
          </Container>
        </ActionWrapper>
        <FooterActionWrapper>
          <Container>
            <SkeletonButton onClick={onGetAllAgents}>Get All Agents</SkeletonButton>
          </Container>
        </FooterActionWrapper>
      </Page>
      {showAllAgents && (
        <Page>
          <AllAgents onLoadAgent={onLoadAgent} onRunAgent={onRun} />
        </Page>
      )}
    </PanelWrapper>
  );
};
