import React from 'react';
import { handle400Statuses, sendApiRequest } from '../../../services/api';
import { logNetRequest } from '../../../utils/usefulFuncs';
import { Body5, Container } from '../../../libs/nvstr-common-ui.es';
import ErrorText from '../../../components/UI/ErrorText';
import { Working } from '../../../components/UI/Working';
import { FooterSeparator } from '../../../components/layout/Body';
import { FlatButton } from '../../../components/buttons';
import styled from 'styled-components';

const ButtonRow = styled.div`
  display: flex;
  flex-direction: row;

  button {
    padding: 4px 8px;
    width: 60px;
  }
`;

export const AllAgents = ({ onLoadAgent }) => {
  const [loading, setLoading] = React.useState(true);
  const [results, setResults] = React.useState(null);
  const [error, setError] = React.useState(null);

  const onLoadClickBound = (id) => {
    return () => onLoadAgent(id);
  };

  const getAllAgents = async () => {
    setError(null);
    const URL = `v1/genai_agent_script_definitions`;
    const { status, data } = await sendApiRequest('get', URL);
    logNetRequest(status, data);
    handle400Statuses(status);
    if (status === 200) {
      setResults(data);
      setLoading(false);
      return true;
    } else {
      setError('Something went wrong, refresh and try again.');
      setLoading(false);
      return false;
    }
  };

  React.useEffect(() => {
    getAllAgents();
  }, []);

  return (
    <Container>
      {error && (
        <Container>
          <ErrorText>{error}</ErrorText>
        </Container>
      )}
      {loading ? (
        <Working />
      ) : (
        <Container>
          <Container top={8}>
            <Body5>All Agent Results</Body5>
          </Container>
          <FooterSeparator />
          {results.map((d) => (
            <Container key={d.id} row verticallyCenter bottom={12}>
              <Container right={24} style={{ width: '380px' }}>
                <Body5 uppercase>{d.script_name}</Body5>
              </Container>
              <ButtonRow>
                <Container left={8}>
                  <FlatButton onClick={onLoadClickBound(d.id)}>Load</FlatButton>
                </Container>
              </ButtonRow>
            </Container>
          ))}
        </Container>
      )}
    </Container>
  );
};
