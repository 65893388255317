import React from 'react';
import { NodeHeader } from './components/NodeHeader';
import { NodeContentWrapper } from './styles';
import InputSourceTargetHandle from './components/handles/InputSourceTargetHandle';
import ExecutionFlowSourceHandle from './components/handles/ExecutionFlowSourceHandle';
import ExecutionFlowTargetHandle from './components/handles/ExecutionFlowTargetHandle';
import { Body5, Container } from '../../../libs/nvstr-common-ui.es';
import styled from 'styled-components';
import { NODE_HANDLE_IDS, NODE_TYPES } from './index';
import { NodeDebugger } from './components/NodeDebugger';
import ExportDataHandle from './components/handles/ExportDataHandle';
import { useEdges, useNodes, useUpdateNodeInternals } from '@xyflow/react';
import { NodeWrapper } from './components/NodeWrapper';

const Wrapper = styled(NodeContentWrapper)`
  max-width: 240px;
`;

function getConnectedActionFromNodes(id, nodes, edges) {
  const edge = edges.find(
    (connection) => connection.target === id && connection.sourceHandle === NODE_HANDLE_IDS.ForEachAction
  );
  if (!edge) return null;

  const nodeId = edge.source;
  return nodes.find((node) => node.id === nodeId) || null;
}

export default React.memo((props) => {
  const { data, id } = props;
  const updateNodeInternals = useUpdateNodeInternals();

  const [action, setAction] = React.useState(null);

  const nodes = useNodes();
  const edges = useEdges();

  React.useEffect(() => {
    const action = getConnectedActionFromNodes(id, nodes, edges);
    setAction(action);
    updateNodeInternals(id);
  }, [nodes, edges]);
  const name = 'For Each Company';
  return (
    <NodeWrapper data-id={id} name={name} data={data}>
      <NodeHeader id={id} name={name} actions={data.actions} />
      <ExecutionFlowSourceHandle {...props} />
      <ExecutionFlowTargetHandle {...props} />

      <Wrapper>
        <Container bottom={84}>
          <Body5>Add an action for each company in the answer list</Body5>
        </Container>
      </Wrapper>
      <InputSourceTargetHandle {...props} handleActionId={NODE_HANDLE_IDS.CompanyList} index={1} />
      <InputSourceTargetHandle {...props} handleActionId={NODE_HANDLE_IDS.ForEachAction} index={0} />

      {action?.type === NODE_TYPES.ForEachIngestWebsiteNode && (
        <ExportDataHandle {...props} handleActionId={NODE_HANDLE_IDS.DocumentList} />
      )}
      <NodeDebugger id={id} />
    </NodeWrapper>
  );
});
