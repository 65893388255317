import React from 'react';
import { NodeHeader } from './components/NodeHeader';
import { NodeContentWrapper } from './styles';
import { Working } from '../../../components/UI/Working';
import { JSONAnswer } from '../../DigitalAnalyst/components/AnswerLine';
import { Body5, Body7, Container } from '../../../libs/nvstr-common-ui.es';
import styled from 'styled-components';
import InputSourceTargetHandle from './components/handles/InputSourceTargetHandle';
import ExecutionFlowSourceHandle from './components/handles/ExecutionFlowSourceHandle';
import ExecutionFlowTargetHandle from './components/handles/ExecutionFlowTargetHandle';
import { NODE_HANDLE_IDS } from './index';
import ExportDataHandle from './components/handles/ExportDataHandle';
import { NodeActionsSpace } from './components/NodeActionsSpace';
import { NodeDebugger } from './components/NodeDebugger';
import { NodeWrapper } from './components/NodeWrapper';

const ContentWrapper = styled(NodeContentWrapper)`
  min-width: 500px;
  min-height: 220px;

  textarea {
    height: 160px;
  }
`;
const CompanyListWrapper = styled.div`
  padding-top: 16px;
  padding-left: 16px;
`;
const CompanyListItemWrapper = styled.div`
  padding: 4px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

const CompanyListItem = ({ name, symbol }) => {
  return (
    <CompanyListItemWrapper>
      <Container>
        <Body5>{name}</Body5>
      </Container>
      <Container left={8}>
        <Body5>({symbol})</Body5>
      </Container>
    </CompanyListItemWrapper>
  );
};
const CompanyList = ({ data }) => {
  return (
    <CompanyListWrapper>
      {data.map((item, index) => (
        <CompanyListItem key={index} name={item.name} symbol={item.symbol} />
      ))}
    </CompanyListWrapper>
  );
};

const Content = ({ id, data, value, onTextChange }) => {
  const { answer, error, isComplete } = data;

  if (error) {
    return (
      <ContentWrapper>
        <Container bottom={8}>
          <Body5>Something went wrong.</Body5>
        </Container>
        <Body5>{error}</Body5>
      </ContentWrapper>
    );
  }
  if (answer === 'Working...') {
    return (
      <ContentWrapper>
        <Container>
          <Body7>{value}</Body7>
        </Container>
        <Working />
      </ContentWrapper>
    );
  }
  if (answer) {
    return (
      <ContentWrapper>
        {!isComplete && (
          <Container bottom={8}>
            <Working noVerticalPadding />
          </Container>
        )}
        <Container>
          <Body5>List Description: {value}</Body5>
        </Container>
        <JSONAnswer result={answer} renderer={(parsedList) => <CompanyList data={parsedList} />} />
      </ContentWrapper>
    );
  }
  return (
    <ContentWrapper>
      <textarea placeholder="Describe the type of list of companies" value={value} onChange={onTextChange} />
    </ContentWrapper>
  );
};

export const GenerateCompanyListNode = (props) => {
  const { data, id } = props;

  // const example = ' major car company with the most revenue';
  const [value, setValue] = React.useState('');

  React.useEffect(() => {
    data.actions.updateData({ question: value });
  }, [value]);

  // for forced testing
  // React.useEffect(() => {
  //   const answer = '```json [{"name": "Tesla","symbol": "TSLA"}]```';
  //   data.actions.updateData({ answer: answer });
  // }, []);

  const onTextChange = (e) => {
    setValue(e.target.value);
  };
  const name = 'Company List';
  return (
    <NodeWrapper data-id={id} name={name} data={data}>
      <NodeHeader id={id} name={name} actions={data.actions} />
      <Content id={id} data={data} value={value} onTextChange={onTextChange} />
      <ExecutionFlowSourceHandle {...props} />
      <ExecutionFlowTargetHandle {...props} />
      <NodeActionsSpace rowsCount={2} />
      <InputSourceTargetHandle {...props} handleActionId={NODE_HANDLE_IDS.CompanyList} index={0} />
      <InputSourceTargetHandle {...props} handleActionId={NODE_HANDLE_IDS.DocumentList} index={1} />
      <ExportDataHandle {...props} handleActionId={NODE_HANDLE_IDS.CompanyList} />
      <NodeDebugger id={id} />
    </NodeWrapper>
  );
};
