import styled, { css } from 'styled-components';
import {
  Body5,
  Body7,
  Checkbox,
  Container,
  SVG_LOADING_ICON_TYPES,
  SvgLoadingIcon,
} from '../../../libs/nvstr-common-ui.es';
import React from 'react';
import { sendApiRequest } from '../../../services/api';
import { useColorTheme } from '../../../hooks';
import { FlatButton, TransparentFlatButton } from '../../../components/buttons';
import {
  createTimeInstance,
  DISPLAY_FORMAT_TYPES,
  formatLocalizedDateTime,
  formatValueTo,
} from '../../../libs/nvstr-utils.es';
import DatePicker from 'react-date-picker';

import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import { InfoIconTypes } from '../../../constants/infoIcons';
import InfoIconTooltip from '../../../components/UI/InfoIconTooltip';
import { FED_DOC_TYPE_MODES } from '../../../constants';
import { removeWhitespace } from '../../../utils/usefulFuncs';
import { SkeletonButton } from '../../../components/buttons/SkeletonButton';
import { ROUTES } from '../../../constants/routes';

const Label = styled.div`
  min-width: 120px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const EditButton = styled.div`
  min-width: 160px;
  border-radius: 3px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 12px 8px;
  cursor: pointer;
  transition: all 0.16s;
  max-width: 100%;

  .button-text {
    max-width: calc(100% - 40px);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &:hover {
    background: ${({ theme }) => theme.themeColors.primaryCtaButton};

    * {
      color: ${({ theme }) => theme.themeColors.buttonText};
      fill: ${({ theme }) => theme.themeColors.buttonText};
    }
  }

  svg {
    height: 14px;
    width: 14px;

    * {
      fill: ${({ theme }) => theme.themeColors.text};
    }
  }
`;
const Textarea = styled.textarea`
  font-size: 12px;
  padding: 8px 8px;
  min-height: 80px;
  width: min(500px, 50vw);
  border: 1px solid ${({ theme }) => theme.themeColors.text};
  border-radius: 5px;
  resize: vertical;

  ${({ $Disabled, theme }) =>
    $Disabled
      ? css`
          background: ${({ theme }) => theme.themeColors.border};
          opacity: 0.4;
          cursor: not-allowed;
          border: none;
        `
      : ``};
`;
const EditModeWrapper = styled.div`
  position: relative;
  border: 1px solid ${({ theme }) => theme.themeColors.border};
  border-radius: 5px;
  padding: 12px 24px 24px 24px;
  margin: 16px 0 0 0;
  //max-width: 400px;

  input {
    padding: 8px 8px;
    max-width: 100px;
    border: 1px solid ${({ theme }) => theme.themeColors.text};
    border-radius: 5px;
  }

  .tickers {
    z-index: 20;
  }
`;

const FormButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 48px;
  width: 100%;
  max-width: 300px;

  button {
    padding: 12px 48px;
  }
`;
const DocTypesSelectorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  padding-top: 16px;
`;
const DocTypeSelectInputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  padding: 12px 16px;

  background: ${({ theme }) => theme.themeColors.lowContrastBorder};
  border-radius: 5px;
  cursor: pointer;

  &:hover .checkbox label::after {
    opacity: 0.3;
  }

  .checkbox {
    position: relative;
    width: 20px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    label {
      width: 20px;
      height: 20px;
      cursor: pointer;
      position: absolute;
      top: 0;
      left: 0;

      //background: linear-gradient(top, #222 0%, #888 100%);
      border: 1px solid ${({ theme }) => theme.themeColors.border};
      border-radius: 2px;

      &:after {
        content: '';
        width: 9px;
        height: 5px;
        position: absolute;
        top: 4px;
        left: 3px;
        border: 3px solid ${({ theme }) => theme.themeColors.primaryCtaButton};
        border-top: none;
        border-right: none;
        opacity: 0;
        transform: rotate(-45deg);
      }

      &:hover::after {
        opacity: 0.3;
      }
    }

    input[type='checkbox'] {
      visibility: hidden;

      &:checked + label:after {
        opacity: 1;
      }
    }
  }
`;

const DatePickerWrapper = styled.div`
  height: 48px;
  padding: 16px 0 0 0;

  .react-date-picker__inputGroup__input {
    border: none;
    padding: 0 8px;
  }

  .react-date-picker__inputGroup__input {
    &.react-date-picker__inputGroup__year {
      min-width: 56px;
    }

    min-width: 40px;
    text-align: center;
  }

  .react-date-picker__wrapper {
    border: 1px solid ${({ theme }) => theme.themeColors.border};
    border-radius: 4px;
    padding: 8px;
  }

  .react-calendar {
    background: ${({ theme }) => theme.themeColors.appBackground};

    color: ${({ theme }) => theme.themeColors.text};

    .react-calendar__tile--now {
      border: 1px solid ${({ theme }) => theme.themeColors.primaryCtaButton};
      background: ${({ theme }) => theme.themeColors.appBackground};
      color: ${({ theme }) => theme.themeColors.buttonText};
    }

    button {
      background: ${({ theme }) => theme.themeColors.appBackground} !important;
      color: ${({ theme }) => theme.themeColors.text} !important;

      &:disabled {
        cursor: not-allowed;
      }

      &:enabled:hover {
        border-radius: 2px;
        background: ${({ theme }) => theme.themeColors.primaryCtaButton} !important;
        color: ${({ theme }) => theme.themeColors.buttonText} !important;

        * {
          color: ${({ theme }) => theme.themeColors.buttonText} !important;
        }
      }
    }
  }

  .react-calendar__navigation button:disabled {
    background: ${({ theme }) => theme.themeColors.appBackground};
    color: ${({ theme }) => theme.themeColors.text};
  }

  .react-calendar__tile--active {
    background: ${({ theme }) => theme.themeColors.primaryCtaButton};
    color: ${({ theme }) => theme.themeColors.buttonText};
  }

  .react-calendar__tile:disabled {
    background: ${({ theme }) => theme.themeColors.border};
    color: ${({ theme }) => theme.themeColors.text};
  }
`;

const CalendarIconWrapper = styled.div`
  svg {
    height: 22px;
    width: 22px;

    fill: ${({ theme }) => theme.themeColors.text};
  }
`;

function OpenCalenderIcon() {
  return (
    <CalendarIconWrapper>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <path d="M14,3V5H17.59L7.76,14.83L9.17,16.24L19,6.41V10H21V3M19,19H5V5H12V3H5C3.89,3 3,3.9 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V12H19V19Z" />
      </svg>
    </CalendarIconWrapper>
  );
}

function ClearIcon() {
  return (
    <CalendarIconWrapper>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <path d="M19,15.59L17.59,17L14,13.41L10.41,17L9,15.59L12.59,12L9,8.41L10.41,7L14,10.59L17.59,7L19,8.41L15.41,12L19,15.59M22,3A2,2 0 0,1 24,5V19A2,2 0 0,1 22,21H7C6.31,21 5.77,20.64 5.41,20.11L0,12L5.41,3.88C5.77,3.35 6.31,3 7,3H22M22,5H7L2.28,12L7,19H22V5Z" />
      </svg>
    </CalendarIconWrapper>
  );
}

const EditIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path d="M5,3C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V12H19V19H5V5H12V3H5M17.78,4C17.61,4 17.43,4.07 17.3,4.2L16.08,5.41L18.58,7.91L19.8,6.7C20.06,6.44 20.06,6 19.8,5.75L18.25,4.2C18.12,4.07 17.95,4 17.78,4M15.37,6.12L8,13.5V16H10.5L17.87,8.62L15.37,6.12Z" />
    </svg>
  );
};

const customParseFloat = (v) => {
  const numString = v.toString();
  return parseFloat(numString.replace(/,/g, '')).toString(10);
};
const convertToMillions = (v) => {
  if (!v) return v;
  return customParseFloat(v) / 1000000;
};

function DocTypeSelectInput({ type, value, onChange }) {
  const hc = () => {
    onChange(type, !value);
  };
  return (
    <DocTypeSelectInputWrapper onClick={hc}>
      <div className={'checkbox'}>
        <input
          type="checkbox"
          id="checkbox"
          checked={value}
          onClick={() => null}
          style={{ cursor: 'pointer', marginTop: 0, marginLeft: '0px', width: '14px' }}
          readOnly
        />
        <label className="not-selectable"></label>
      </div>
      <Container left={16}>
        <Body5 bold>{type}</Body5>
      </Container>
    </DocTypeSelectInputWrapper>
  );
}

const WatchlistLink = ({ onResetFilters }) => {
  const onClick = () => {
    const url = ROUTES.watchlist;
    window.open(url, '_blank');
  };

  return (
    <Container top={16} row verticallyCenter>
      <SkeletonButton onClick={onClick}>View Watchlist</SkeletonButton>
      <Container left={16}>
        <TransparentFlatButton onClick={onResetFilters}>Reset Filters</TransparentFlatButton>
      </Container>
    </Container>
  );
};

function DocTypesSelector({ value, onChange, fedDocs }) {
  const [docTypes, setDocTypes] = React.useState(null);

  React.useEffect(() => {
    let retries = 0;
    let cancel = false;
    const getDocTypes = async () => {
      retries += 1;
      const URL = `v1/genai_doc_types_dates_get`;
      const { status, data, error } = await sendApiRequest('get', URL);
      if (status === 200) {
        const { all_doc_types, default_doc_types, default_end_date, default_start_date, max_date, min_date } = data;
        if (fedDocs) {
          setDocTypes(FED_DOC_TYPE_MODES);
        } else {
          setDocTypes(all_doc_types);
        }
      } else {
        setTimeout(() => {
          if (!cancel && retries < 5) {
            getDocTypes();
          }
        }, 400);
      }
    };
    getDocTypes();

    return () => {
      cancel = true;
    };
  }, []);

  if (!docTypes) {
    return null;
  }
  return (
    <DocTypesSelectorWrapper>
      {docTypes.map((dt) => (
        <DocTypeSelectInput type={dt} value={value.includes(dt)} onChange={onChange} />
      ))}
    </DocTypesSelectorWrapper>
  );
}

export function FilterForm({
  isLoadingDocs,
  minMarketCap,
  maxMarketCap,
  docTypes,
  startDate,
  tickers,
  ignoreTickers,
  onFiltersChange,
  onResetFilters,
  onlyWatchlist,
  enableWatchlist,
  enableMarketCap,
  enableTickers,
  fedDocs,
  enableWatchlistLink,
}) {
  // const minDate = createTimeInstance().subtract(3, 'months').toDate();
  const maxDate = createTimeInstance().subtract(1, 'day').toDate();

  const colorTheme = useColorTheme();
  const [inEditMode, setInEditMode] = React.useState(false);

  const [minMarketCapFormValue, setMinMarketCapFormValue] = React.useState(convertToMillions(minMarketCap));
  const [maxMarketCapFormValue, setMaxMarketCapFormValue] = React.useState(convertToMillions(maxMarketCap));
  const [docTypesFormValue, setDocTypesFormValue] = React.useState(docTypes);
  const [startDateFormValue, setStartDateFormValue] = React.useState(startDate);
  const [tickersFormValue, setTickersFormValue] = React.useState(tickers);
  const [ignoreTickersFormValue, setIgnoreTickersFormValue] = React.useState(ignoreTickers);
  const [onlyWatchlistFormValue, setOnlyWatchlistFormValue] = React.useState(enableWatchlist ? onlyWatchlist : false);

  React.useEffect(() => {
    setMinMarketCapFormValue(convertToMillions(minMarketCap));
    setMaxMarketCapFormValue(convertToMillions(maxMarketCap));
    setDocTypesFormValue(docTypes);
    setStartDateFormValue(startDate);
    setTickersFormValue(tickers);
    setIgnoreTickersFormValue(ignoreTickers);
    setOnlyWatchlistFormValue(enableWatchlist ? onlyWatchlist : false);
  }, [minMarketCap, maxMarketCap, docTypes, startDate, ignoreTickers, onlyWatchlist]);

  const handleEditClick = () => {
    setInEditMode(true);
  };

  const handleSave = () => {
    const form = {
      docTypes: docTypesFormValue,
      startDate: startDateFormValue,
      onlyWatchlist: onlyWatchlistFormValue,
    };
    if (enableMarketCap) {
      form.minMarketCap = minMarketCapFormValue * 1000000;
      form.maxMarketCap = maxMarketCapFormValue * 1000000;
    }

    if (enableTickers) {
      form.tickers = tickersFormValue;
      form.ignoreTickers = ignoreTickersFormValue;
    }
    if (onlyWatchlistFormValue) {
      form.ignoreTickers = false;
      form.tickers = '';
    }
    onFiltersChange(form);
    setInEditMode(false);
  };
  const handleCancel = () => {
    setMinMarketCapFormValue(convertToMillions(minMarketCap));
    setMaxMarketCapFormValue(convertToMillions(maxMarketCap));
    setDocTypesFormValue(docTypes);
    setStartDateFormValue(startDate);
    setTickersFormValue(tickers);
    setIgnoreTickersFormValue(ignoreTickers);
    setOnlyWatchlistFormValue(onlyWatchlist);
    setInEditMode(false);
  };

  const handleIgnoreTickersChange = (e) => {
    return () => setIgnoreTickersFormValue(!ignoreTickersFormValue);
  };
  const handleOnlyWatchlistChange = (e) => {
    return () => setOnlyWatchlistFormValue(!onlyWatchlistFormValue);
  };
  const handleMinMarketCapInputChange = (e) => {
    const {
      target: { value },
    } = e;
    setMinMarketCapFormValue(value);
  };
  const handleMaxMarketCapInputChange = (e) => {
    const {
      target: { value },
    } = e;
    setMaxMarketCapFormValue(value);
  };
  const handleTickersInputChange = (e) => {
    const {
      target: { value },
    } = e;
    setTickersFormValue(removeWhitespace(value));
  };
  const handleDocTypesInputChange = (type, value) => {
    if (value) {
      setDocTypesFormValue([...docTypesFormValue, type]);
    } else {
      setDocTypesFormValue(docTypesFormValue.filter((v) => v !== type));
    }
  };
  const handleStartDateChange = (value) => {
    if (value === null) {
      setStartDateFormValue(startDate);
    } else {
      setStartDateFormValue(value);
    }
  };

  const handleSelectAllDocTypes = () => {
    setDocTypesFormValue(docTypes);
  };

  const handleUnselectAllDocTypes = () => {
    setDocTypesFormValue([]);
  };

  if (inEditMode) {
    return (
      <EditModeWrapper>
        {enableWatchlist && (
          <Container row verticallyCenter bottom={12} top={8}>
            <Container>
              <Label>
                <Body7>Use Only Watchlist Companies: </Body7>
              </Label>
            </Container>

            <Container row verticallyCenter left={16}>
              <Checkbox
                name={'disable-ticker-filter'}
                value={onlyWatchlistFormValue}
                onChange={handleOnlyWatchlistChange}
              />
              <div onClick={handleOnlyWatchlistChange()}>
                {/*<Container bottom={3} style={{ cursor: 'pointer' }}>*/}
                {/*  <Body7>Disable Ticker Filtering</Body7>*/}
                {/*</Container>*/}
              </div>
            </Container>
          </Container>
        )}

        <Container row verticallyCenter top={12}>
          <Label>
            <Body7>Since: </Body7>
          </Label>
        </Container>
        <Container>
          <DatePickerWrapper>
            <DatePicker
              name={'start-date'}
              calendarIcon={OpenCalenderIcon}
              clearIcon={ClearIcon}
              onChange={handleStartDateChange}
              value={startDateFormValue}
              // minDate={minDate}
              maxDate={maxDate}
            />
          </DatePickerWrapper>
        </Container>
        <Container top={24}></Container>
        <Container row verticallyCenter top={12}>
          <Label>
            <Body7>Include Doc Types: </Body7>
          </Label>

          <Container left={24}>
            <TransparentFlatButton onClick={handleSelectAllDocTypes}>Select All</TransparentFlatButton>
          </Container>
          <Container left={24}>
            <TransparentFlatButton onClick={handleUnselectAllDocTypes}>Unselect All</TransparentFlatButton>
          </Container>
        </Container>
        <Container>
          <DocTypesSelector onChange={handleDocTypesInputChange} value={docTypesFormValue} fedDocs={fedDocs} />
        </Container>
        {enableMarketCap && (
          <Container row verticallyCenter height={60} top={12}>
            <Label>
              <Body7>Min Market Cap: </Body7>
            </Label>
            <Container row verticallyCenter>
              <input value={minMarketCapFormValue} onChange={handleMinMarketCapInputChange} />
              <Container left={8}>
                <Body5>Million</Body5>
              </Container>
            </Container>
          </Container>
        )}
        {enableMarketCap && (
          <Container row verticallyCenter height={60} bottom={16}>
            <Label>
              <Body7>Max Market Cap: </Body7>
            </Label>
            <Container row verticallyCenter>
              <input value={maxMarketCapFormValue} onChange={handleMaxMarketCapInputChange} />
              <Container left={8}>
                <Body5>Million</Body5>
              </Container>
            </Container>
          </Container>
        )}
        {enableTickers && !onlyWatchlistFormValue && (
          <Container row verticallyCenter bottom={16}>
            <Label>
              <Body7>Tickers: </Body7>
              <Container left={7} top={2}>
                <InfoIconTooltip type={InfoIconTypes.CSV} />
              </Container>
            </Label>
            <Container row verticallyCenter>
              <div className={'tickers'}>
                <Textarea
                  placeholder={'AAPL,MSFT,AMZN...'}
                  value={tickersFormValue}
                  onChange={handleTickersInputChange}
                  readOnly={ignoreTickersFormValue}
                  $Disabled={ignoreTickersFormValue}
                />

                <Container row verticallyCenter top={8}>
                  <Checkbox
                    name={'disable-ticker-filter'}
                    value={ignoreTickersFormValue}
                    onChange={handleIgnoreTickersChange}
                  />
                  <div onClick={handleIgnoreTickersChange()}>
                    <Container bottom={3} style={{ cursor: 'pointer' }}>
                      <Body7>Disable Ticker Filtering</Body7>
                    </Container>
                  </div>
                </Container>
              </div>
            </Container>
          </Container>
        )}

        <FormButtonWrapper>
          <TransparentFlatButton onClick={handleCancel}>Cancel</TransparentFlatButton>
          <FlatButton onClick={handleSave}>Save</FlatButton>
        </FormButtonWrapper>
      </EditModeWrapper>
    );
  }
  return (
    <Container top={8}>
      {enableWatchlist && (
        <Container row verticallyCenter>
          <Label>
            <Body7>Use Only Watchlist: </Body7>
          </Label>
          <EditButton onClick={handleEditClick}>
            <Body7>{onlyWatchlist ? 'On' : 'Off'}</Body7>
            <EditIcon />
          </EditButton>
        </Container>
      )}
      <Container row verticallyCenter>
        <Label>
          <Body7>Since: </Body7>
        </Label>
        <EditButton onClick={handleEditClick}>
          <Body7>{formatLocalizedDateTime('-L', createTimeInstance(startDate))}</Body7>
          <EditIcon />
        </EditButton>
      </Container>
      <Container row verticallyCenter>
        <Label>
          <Body7>Include Doc Types: </Body7>
        </Label>
        <EditButton onClick={handleEditClick}>
          {docTypes.length === 0 ? <Body7>All</Body7> : <Body7>Filtered</Body7>}

          <EditIcon />
        </EditButton>
        {isLoadingDocs && (
          <Container left={16}>
            <SvgLoadingIcon type={SVG_LOADING_ICON_TYPES.bars} color={colorTheme.text} />
          </Container>
        )}
      </Container>

      {enableMarketCap && (
        <Container row verticallyCenter>
          <Label>
            <Body7>Min Market Cap: </Body7>
          </Label>
          <EditButton onClick={handleEditClick}>
            <Body7>$</Body7>
            <Body7>{formatValueTo(DISPLAY_FORMAT_TYPES.INTEGER, minMarketCap)}</Body7>
            <EditIcon />
          </EditButton>
          {isLoadingDocs && (
            <Container left={16}>
              <SvgLoadingIcon type={SVG_LOADING_ICON_TYPES.bars} color={colorTheme.text} />
            </Container>
          )}
        </Container>
      )}

      {enableMarketCap && (
        <Container row verticallyCenter>
          <Label>
            <Body7>Max Market Cap: </Body7>
          </Label>
          <EditButton onClick={handleEditClick}>
            <Body7>$</Body7>
            <Body7>{formatValueTo(DISPLAY_FORMAT_TYPES.INTEGER, maxMarketCap)}</Body7>
            <EditIcon />
          </EditButton>
        </Container>
      )}
      {onlyWatchlist ? null : enableTickers ? (
        ignoreTickers ? (
          <Container row verticallyCenter>
            <Label>
              <Body7>Tickers: </Body7>
            </Label>
            <EditButton onClick={handleEditClick}>
              <div className={'button-text'} style={{ maxWidth: '300px' }}>
                <Body7>Disabled</Body7>
              </div>
              <EditIcon />
            </EditButton>
          </Container>
        ) : (
          <Container row verticallyCenter>
            <Label>
              <Body7>Tickers: </Body7>
            </Label>
            <EditButton onClick={handleEditClick}>
              <div className={'button-text'} style={{ maxWidth: '300px' }}>
                <Body7>{tickers === '' ? 'All' : tickers}</Body7>
              </div>
              <EditIcon />
            </EditButton>
          </Container>
        )
      ) : null}
      {enableWatchlistLink && <WatchlistLink onResetFilters={onResetFilters} />}
    </Container>
  );
}
