import { applicationActionTypes, constantsActions, currentUserActionTypes } from '../constants/actionTypes';
import { handle400Statuses, sendApiRequest } from './api';
import { setFaviconSVG } from '../utils/application';
import { setGlobalUser } from '../utils';

export const fetchCurrentUser = () => {
  return async (dispatch) => {
    const response = await sendApiRequest('get', 'users?include_self=true', null, null);
    const { status, data } = response;
    if (status === 200 && data?.users?.length > 0) {
      const currentUser = data.users[0];
      // logger('current user', currentUser);
      setGlobalUser(currentUser);
      dispatch({
        type: currentUserActionTypes.setCurrentUser,
        payload: currentUser,
      });
    }
    return response;
  };
};

export const fetchCustomClientDetails = () => {
  return async (dispatch) => {
    const URL = 'v1/custom_client_svg';
    const { status, data } = await sendApiRequest('get', URL);
    handle400Statuses(status);
    if (status === 200 && data) {
      const { title, favi } = data;
      dispatch({
        type: applicationActionTypes.setCustomClientLogo,
        payload: data,
      });

      if (title) {
        document.title = title;
      }
      if (favi) {
        setFaviconSVG(favi);
      }
    } else {
      dispatch({
        type: applicationActionTypes.setCustomClientLogo,
        payload: null,
      });
    }
  };
};

export const fetchToolsAvailable = () => {
  return async (dispatch) => {
    const response = await sendApiRequest('get', 'v1/genai_mode_types_get', null, null);
    const { status, data } = response;
    if (status === 200 && data?.ai_allowed_mode_types) {
      dispatch({
        type: currentUserActionTypes.setCurrentUser,
        payload: {
          ai_allowed_mode_types: data.ai_allowed_mode_types,
          permissioned_modes_metadata: data.permissioned_modes_metadata,
          grouped_permissioned_modes_metadata: data?.permissioned_modes_metadata_grouped?.mode_groups || [],
        },
      });
    }
    return response;
  };
};

export const fetchDocTypesAvailable = () => {
  return async (dispatch) => {
    const response = await sendApiRequest('get', 'v1/genai_doc_types_dates_get', null, null);
    const { status, data } = response;
    if (status === 200) {
      const { all_doc_types, default_doc_types, default_end_date, default_start_date, max_date, min_date } = data;
      dispatch({
        type: constantsActions.updateDocTypes,
        payload: all_doc_types,
      });
    }
    return response;
  };
};

export const updateCurrentUser = (properties) => {
  return async (dispatch) => {
    const response = await sendApiRequest('put', 'current_user', properties, null);
    const { status, data } = response;
    if (status === 200 && data) {
      const currentUser = data;
      dispatch({
        type: currentUserActionTypes.updateCurrentUser,
        payload: currentUser,
      });
      return { ok: true };
    } else {
      return { error: 'Something went wrong, please try again.' };
    }
  };
};
