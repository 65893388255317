import React from 'react';
import styled from 'styled-components';
import { Page } from '../../libs/nvstr-common-ui.es';
import { Auth } from './AgentScriptEditor/Auth';
import { Editor } from './AgentScriptEditor/Editor';
import { Output } from './AgentScriptEditor/Output';
import { TrackingEvent } from '../../libs/nvstr-utils.es';
import { handle400Statuses, sendApiRequest } from '../../services/api';
import { logNetRequest, scrollToTop } from '../../utils/usefulFuncs';
import { AI_TOOLS } from '../../constants';

const PageWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  padding: 0 16px 0 0px;
`;

function generateAuthHeaders(authData) {
  const headers = [];
  headers.push(['client', authData.client]);
  headers.push(['expiry', authData.expiry]);
  headers.push(['access-token', authData.accessToken]);
  headers.push(['uid', authData.uid]);
  return headers;
}

const tool = AI_TOOLS.AGENT_SCRIPT_EDITOR;

function AgentModeTool() {
  const [outputAgentName, setOutputAgentName] = React.useState(null);
  const [authData, setAuthData] = React.useState(null);

  const [result, setResult] = React.useState(null);
  const [error, setError] = React.useState(false);
  const [executed, setExecuted] = React.useState(false);
  const [prompt, setPrompt] = React.useState('');
  const [executedPlan, setExecutedPlan] = React.useState(null);
  const [includeExecutedPlan, setIncludeExecutedPlan] = React.useState(true);

  React.useEffect(() => {
    return () => {
      clearData();
    };
  }, []);

  const clearData = () => {
    setResult(null);
    setError(null);
    setExecuted(false);
    setPrompt('');
    setExecutedPlan(null);
    setIncludeExecutedPlan(true);
  };

  const clearRunResults = () => {
    setResult(null);
    setError(null);
    setExecuted(false);
    setExecutedPlan(null);
    setIncludeExecutedPlan(true);
  };

  const runAgent = async (agentName, prompt) => {
    const form = {
      agent_name: agentName,
      include_executed_plan_data: includeExecutedPlan,
      user_mode: tool,
      question: prompt,
    };

    TrackingEvent.create('Run Agent Script', {
      AgentName: agentName,
      Question: prompt,
    });

    const URL = `v1/genai_qa`;
    let config = { headers: generateAuthHeaders(authData) };

    const { status, data, error } = await sendApiRequest('post', URL, form, config);
    logNetRequest(status, data, error);
    handle400Statuses(status);
    if (status === 200) {
      setResult(data.answer);
      if (includeExecutedPlan) setExecutedPlan(data.executed_plan);
      return data;
    } else {
      TrackingEvent.create('Run Agent Script Failed', {
        AgentName: agentName,
        Question: prompt,
        Error: data?.error || error || 'unknown',
      });
      const message = data?.error || error || 'Something went wrong, try again.';
      setError(message);
      return {
        error: message,
      };
    }
  };

  const onExecute = () => {
    setExecuted(true);
    runAgent(outputAgentName, prompt);
  };

  const onRun = (v) => {
    scrollToTop(200);

    if (outputAgentName === v && prompt !== '') {
      clearRunResults();
      onExecute();
    } else {
      setOutputAgentName(v);
    }
  };

  const onAuthComplete = (authData) => {
    setAuthData(authData);
  };

  const onCloseOutput = () => {
    setOutputAgentName(null);
    clearData();
  };

  const onPromptChange = (e) => {
    setPrompt(e.target.value);
  };

  if (authData === null) {
    return (
      <Page>
        <Auth onAuthComplete={onAuthComplete} />
      </Page>
    );
  }

  return (
    <PageWrapper>
      <Editor onRun={onRun} />
      <Output
        agentName={outputAgentName}
        result={result}
        executedPlan={executedPlan}
        executed={executed}
        prompt={prompt}
        outputError={error}
        onExecute={onExecute}
        onPromptChange={onPromptChange}
        onCloseOutput={onCloseOutput}
      />
    </PageWrapper>
  );
}

export default AgentModeTool;
