import React from 'react';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import { ROUTES } from '../../constants/routes';
import { useSelector } from 'react-redux';
import { getIsAuthed } from '../../utils/selectors';
import { AuthEffectHandler } from '../../classes/AuthEffectHandler';
import InfoIconModal from '../../components/UI/InfoIconModal';
import { Modal } from '../../components/layout/Modal';
import styled from 'styled-components';
import { Body5, Container } from '../../libs/nvstr-common-ui.es';
import { SkeletonButton } from '../../components/buttons/SkeletonButton';
import { PermissionEffectHandler } from '../../classes/PermissionHandler';
import { signOut } from '../../services/authentication';

const Wrapper = styled.div``;

const ALERT_APPROVED_KEY = 'approved_confirm';
const ALERT_FAILED_KEY = 'approval_error';
const UNSUBSCRIBED_KEY = 'unsubscribed';

const ModalParamBasedMessenger = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [modalProps] = React.useState({});

  const [unsubscribedFromAlerts, setUnsubscribedFromAlerts] = React.useState(false);
  const [isAlertApproved, setIsAlertApproved] = React.useState(null);
  const [didAlertFail, setDidAlertFail] = React.useState(null);

  const onDismiss = () => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete(ALERT_APPROVED_KEY);
    searchParams.delete(ALERT_FAILED_KEY);
    searchParams.delete(UNSUBSCRIBED_KEY);
    navigate(location.pathname + searchParams.toString());

    setIsAlertApproved(null);
    setDidAlertFail(null);
    setUnsubscribedFromAlerts(false);
  };

  React.useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if (searchParams.has(ALERT_APPROVED_KEY)) {
      setIsAlertApproved(searchParams.get(ALERT_APPROVED_KEY));
    }
    if (searchParams.has(ALERT_FAILED_KEY)) {
      setDidAlertFail(searchParams.get(ALERT_FAILED_KEY));
    }
    if (searchParams.has(UNSUBSCRIBED_KEY)) {
      const value = searchParams.get(UNSUBSCRIBED_KEY);
      setUnsubscribedFromAlerts(value === '1');
    }
  }, [location]);

  if (isAlertApproved || didAlertFail || unsubscribedFromAlerts) {
    return (
      <Wrapper>
        <Modal onDismiss={onDismiss} modalProps={modalProps}>
          <Container bottom={4}>
            <Body5>
              {isAlertApproved !== null
                ? `Alert email approval successful, sending it to ${isAlertApproved} users.`
                : ''}
            </Body5>
            <Body5>
              {didAlertFail !== null
                ? `Alert email approval encountered an error. Please contact technical support.`
                : ''}
            </Body5>
            <Body5>
              {unsubscribedFromAlerts ? `You have been unsubscribed from Digital Analyst email stock alerts.` : ''}
            </Body5>
          </Container>
          <Container top={24} style={{ textAlign: 'center' }}>
            <SkeletonButton onClick={onDismiss}>Dismiss</SkeletonButton>
          </Container>
        </Modal>
      </Wrapper>
    );
  }

  return null;
};

const AuthStatusManager = () => {
  const isAuthed = useSelector(getIsAuthed);

  const handle401ResponseAfterAuthed = () => {
    window.location = ROUTES.login + '?sessionexpired=1';
    signOut();
  };

  const handle403ResponseAfterAuthed = () => {
    window.location = ROUTES.inReview;
  };

  React.useEffect(() => {
    if (isAuthed) {
      AuthEffectHandler.bindLossOfAuthHandler(handle401ResponseAfterAuthed);
    }

    PermissionEffectHandler.bindLossOfPermissionHandler(handle403ResponseAfterAuthed);
  }, [isAuthed]);

  return null;
};

export const Managers = () => {
  return (
    <>
      <AuthStatusManager />
      <ModalParamBasedMessenger />
      <InfoIconModal />
    </>
  );
};
