import React from 'react';
import styled from 'styled-components';
import { Line } from 'react-chartjs-2';
import { convertHexToRGBA, createTimeInstance, formatLocalizedDateTime } from '../../libs/nvstr-utils.es';
import { Body5 } from '../../libs/nvstr-common-ui.es';
import { calcSuggestedMinMax } from '../../utils/usefulFuncs';
import { useColorTheme } from '../../hooks';

const ChartHeader = styled.div`
  padding: 0 0 16px 48px;
`;
const ChartAnalysisGlobalWrapper = styled.div``;
const ChartAnalysisWrapper = styled.div`
  overflow: auto;
`;
const getDatapointWidth = (TimeOnXAxis) => (TimeOnXAxis ? 20 : 10);
const ChartWrapper = styled.div`
  height: ${({ TimeOnXAxis }) => (TimeOnXAxis ? '660px' : '460px')};
  width: ${({ DataPoints, TimeOnXAxis }) => Math.max(1000, DataPoints * getDatapointWidth(TimeOnXAxis)) + 'px'};
  padding: 0 0 10px 0;

  canvas {
    display: block;
    height: ${({ TimeOnXAxis }) => (TimeOnXAxis ? '660px' : '460px')};
    width: ${({ DataPoints, TimeOnXAxis }) => Math.max(1000, DataPoints * getDatapointWidth(TimeOnXAxis)) + 'px'};
  }
`;

export const DocSentimentChart = ({ data, activeKey, timeOnXAxis }) => {
  const colorTheme = useColorTheme();

  const generateOptions = (data, timeOnXAxis) => {
    const { min, max } = calcSuggestedMinMax(data, 1);

    return {
      maintainAspectRatio: false,

      elements: {
        point: {
          radius: 4,
          hoverRadius: 12,
          pointStyle: 'circle',
        },
        line: {
          borderWidth: 3,
        },
      },

      options: {
        responsive: true,
        maintainAspectRatio: false,
      },

      plugins: {
        tooltip: {
          enabled: true,
        },
        legend: {
          display: false,
        },
        title: {
          display: false,
        },
      },
      scales: {
        y: {
          beginAtZero: false,
          min,
          max,
          ticks: {
            min,
            max,
            stepSize: 0.5,
            color: colorTheme.text,
          },
          title: {
            display: true,
            text: 'Dovish - Neutral - Hawkish',
          },
          grid: {
            color: (context) => (context.tick.major ? colorTheme.border : colorTheme.lowContrastBorder), //  '#aaa' '#fafafa'
          },
        },
        x: {
          beginAtZero: false,
          grid: {
            tickWidth: 1,
            lineWidth: 1,
            color: (context) => (context.tick.major ? colorTheme.border : colorTheme.lowContrastBorder), //  '#aaa' '#fafafa'
          },
          border: {
            display: false,
          },
          ticks: {
            display: !!timeOnXAxis,
            color: colorTheme.text,
            font: {
              family: 'Bolton, Arial',
              size: 12,
              color: colorTheme.text,
            },
            autoSkip: false,
            callback: function (value, index, ticks) {
              const dataValue = data.datapoints[index];
              if (dataValue) {
                const doc = dataValue.doc;
                if (doc) {
                  const reportDate = createTimeInstance(doc.report_date);
                  return formatLocalizedDateTime('ll2', reportDate);
                } else {
                  return value;
                }
              } else {
                return value;
              }
            },
          },
        },
      },
    };
  };

  const [options, setOptions] = React.useState(generateOptions(data, timeOnXAxis));

  React.useEffect(() => {
    setOptions(generateOptions(data, timeOnXAxis));
  }, [data, colorTheme, timeOnXAxis]);

  const chartColor = colorTheme.text; // '#222';
  const chartData = {
    labels: data.labels,
    datasets: [
      {
        label: `Doc Sentiment Over Time (${activeKey})`,
        data: data.datapoints,
        fill: false,
        backgroundColor: chartColor,
        borderColor: convertHexToRGBA(chartColor, 1),
        tension: 0.1,
      },
    ],
  };

  return (
    <ChartAnalysisGlobalWrapper>
      <ChartAnalysisWrapper className={'chart-wrapper'}>
        <ChartHeader>
          <Body5 bold>{`Doc Sentiment Over Time (${activeKey})`}</Body5>
        </ChartHeader>
        <ChartWrapper DataPoints={data.labels.length} TimeOnXAxis={timeOnXAxis}>
          <Line data={chartData} options={options} />
        </ChartWrapper>
      </ChartAnalysisWrapper>
    </ChartAnalysisGlobalWrapper>
  );
};
