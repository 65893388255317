import { useNavigate } from 'react-router';
import { useCurrentUser } from '../../../hooks/user/useCurrentUser';
import React from 'react';
import { ROUTES } from '../../../constants/routes';
import { sendApiRequest } from '../../../services/api';
import BackArrowButton from '../../../components/buttons/BackArrowButton';
import { Body5, Container, H5 } from '../../../libs/nvstr-common-ui.es';
import { FormState } from '../../../components/UI/FormState';
import { FlatButton } from '../../../components/buttons';
import styled from 'styled-components';

const AuthWrapper = styled.div`
  input {
    outline: none;
    border: 1px solid ${({ theme }) => theme.themeColors.text};
    border-radius: 5px;
    font-size: 14px;
    line-height: 18px;
    color: ${({ theme }) => theme.themeColors.text};
    margin: 0;
    padding: 8px 16px;
    width: 420px;
  }

  max-width: 452px;
`;

function buildAuthDataFromHeaders(headers) {
  const authData = {};
  authData.client = headers.get('client');
  authData.expiry = headers.get('expiry');
  authData.uid = headers.get('uid');
  authData.accessToken = headers.get('access-token');
  return authData;
}

export const Auth = ({ onAuthComplete }) => {
  const navigate = useNavigate();
  const currentUser = useCurrentUser();
  const email = currentUser.email;
  const [password, setPassword] = React.useState('');
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [error, setError] = React.useState(null);

  const onGoBack = () => navigate(ROUTES.home);

  const onSubmit = async () => {
    const authform = {
      email,
      password,
      client_platform: 'web',
    };

    setIsSubmitting(true);
    const AUTH_URL = `v1/auth/sign_in`;
    const signInResponse = await sendApiRequest('post', AUTH_URL, authform);
    if (signInResponse.status === 200) {
      const authData = buildAuthDataFromHeaders(signInResponse.headers);
      onAuthComplete(authData);
      setIsSubmitting(false);
    } else {
      setError('Incorrect password, try again.');
      setIsSubmitting(false);
    }
  };

  return (
    <AuthWrapper>
      <BackArrowButton onClick={onGoBack} />

      <Container top={36}>
        <H5>Sign In</H5>
      </Container>
      <Container top={24}>
        <Body5>Email</Body5>
      </Container>
      <Container top={8}>
        <input value={email} type="email" onChange={(e) => null} readOnly />
      </Container>

      <Container top={8}>
        <Container top={8}>
          <Body5>Password</Body5>
        </Container>

        <Container top={8}>
          <input value={password} type="password" onChange={(e) => setPassword(e.target.value)} />
        </Container>
      </Container>

      <Container top={24} bottom={16}>
        <FormState error={error} isSubmitting={isSubmitting} text={'Submitting...'} />
        <FlatButton fullWidth onClick={onSubmit} disabled={isSubmitting}>
          Submit
        </FlatButton>
      </Container>
    </AuthWrapper>
  );
};
