import React from 'react';
import { NodeHeader } from './components/NodeHeader';
import { Body5, Container } from '../../../libs/nvstr-common-ui.es';
import InputSourceHandle from './components/handles/InputSourceHandle';
import { NodeDebugger } from './components/NodeDebugger';
import { AVAILABLE_SETTING_FIELDS, NODE_HANDLE_IDS } from './index';
import { useColorTheme } from '../../../hooks';
import styled from 'styled-components';
import { FlatButton } from '../../../components/buttons';
import {
  AdvancedSettingsFields,
  getAdvancedSettingDefaultValue,
  toAdvancedSettingDisplayName,
} from '../../../hooks/application/useAdvancedSettings';
import { gptModels } from '../../../constants';
import { NodeWrapper } from './components/NodeWrapper';

const ContentWrapper = styled.div`
  min-width: 440px;
  padding: 16px 8px;

  button {
    padding: 12px 12px;
  }
`;
const Select = styled.select`
  width: 250px;
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.themeColors.text};
  padding: 12px 16px;

  font-size: 14px;
  font-weight: bold;
  line-height: 24px;
  -webkit-appearance: auto !important;
`;
const SettingsFieldWrapper = styled.div`
  input {
    border: 1px solid ${({ theme }) => theme.themeColors.border};
    color: ${({ theme }) => theme.themeColors.text};
    background-color: transparent;
    border-radius: 6px;
    width: 100%;
    padding: 8px;
  }

  select {
    border: 1px solid ${({ theme }) => theme.themeColors.border};
    color: ${({ theme }) => theme.themeColors.text};
    background-color: transparent;
    border-radius: 6px;
    width: 100%;
    padding: 8px;
    -webkit-appearance: auto !important;
  }

  textarea {
    border: 1px solid ${({ theme }) => theme.themeColors.border};
    color: ${({ theme }) => theme.themeColors.text};
    background-color: transparent;
    border-radius: 6px;
    min-height: 100px;
    width: 100%;
    padding: 16px;
  }
`;

const Label = styled.div`
  padding-bottom: 8px;
`;

const SettingsField = ({ name, state, setValue }) => {
  // const enableBetaFeatures = useHasBetaFeaturesEnabled();
  const gptModelItems = Object.values(gptModels);

  const {
    enableJS,
    enableSmoothing,
    enableTextSearch,
    enableMath,
    includeInferredMetadata,
    model,
    maxContextLength,
    maxAnswerLength,
    seeContext,
    maxContextChunks,
    version,

    seed,
    temperature,
    topP,

    // enableFollowUpQuestions,
    // enableDailySummaries,
    // enableHeadlines,
    // includeUserDocs,
    // numOfPages,
    // overrideQuestion,
    // recommendedModel,
  } = state;

  React.useEffect(() => {
    // Set Default Value
    const defaultValue = getAdvancedSettingDefaultValue(name);
    if (defaultValue !== null) {
      setValue(name, defaultValue);
    }
  }, []);

  const onSeedChange = (e) => {
    const {
      target: { value },
    } = e;
    setValue('seed', value);
  };
  const onTemperatureChange = (e) => {
    const {
      target: { value },
    } = e;
    setValue('temperature', value);
  };
  const onTopPChange = (e) => {
    const {
      target: { value },
    } = e;
    setValue('topP', value);
  };
  const onGPTModelChange = (e) => {
    const {
      target: { value },
    } = e;
    setValue('model', value);
  };
  const onMaxContextLengthChange = (e) => {
    const {
      target: { value },
    } = e;
    setValue('maxContextLength', value);
  };
  const onVersionChange = (e) => {
    const {
      target: { value },
    } = e;
    setValue('version', value);
  };
  const onMaxContextChunksChange = (e) => {
    const {
      target: { value },
    } = e;
    setValue('maxContextChunks', value);
  };
  const setToggleEnableTextSearch = () => {
    setValue('enableTextSearch', !enableTextSearch);
  };
  const setToggleEnableMath = () => {
    setValue('enableMath', !enableMath);
  };
  const setToggleIncludeInferredMetadata = () => {
    setValue('includeInferredMetadata', !includeInferredMetadata);
  };
  const onSeeContextChange = () => {
    setValue('seeContext', !seeContext);
  };
  const onEnableJSChange = () => {
    setValue('enableJS', !enableJS);
  };
  const onEnableSmoothingChange = () => {
    setValue('enableSmoothing', !enableSmoothing);
  };
  const onMaxAnswerLengthChange = (v) => {
    setValue('maxAnswerLength', v);
  };
  const onMaxAnswerLengthChangeEffect = (e) => {
    const maxValue = 4096;
    const value = e.target.value;
    const intValue = parseInt(value, 10);
    if (intValue > maxValue) {
      onMaxAnswerLengthChange(maxValue.toString());
    } else {
      onMaxAnswerLengthChange(value);
    }
  };

  // const setToggleIncludeUserDocs = () => {
  //   setValue('includeUserDocs', !includeUserDocs);
  // };
  // const onOverrideQuestionChange = (e) => {
  //   const {
  //     target: { value },
  //   } = e;
  //   setValue('overrideQuestion', value);
  // };
  // const onNumOfPagesChange = (e) => {
  //   const {
  //     target: { value },
  //   } = e;
  //   setValue('numOfPages', value);
  // };
  // const onEnableDailySummariesToggle = () => {
  //   setValue('enableDailySummaries', !enableDailySummaries);
  // };
  // const onEnableHeadlinesToggle = () => {
  //   setValue('enableHeadlines', !enableHeadlines);
  // };
  // const onEnableFollowUpQuestionsChange = () => {
  //   setValue('enableFollowUpQuestions', !enableFollowUpQuestions);
  // };

  if (name === AdvancedSettingsFields.model) {
    return (
      <SettingsFieldWrapper>
        <Container top={16}>
          <Label>Model (defaults to recommended version)</Label>
          <select onChange={onGPTModelChange} value={model}>
            {gptModelItems.map((m) => (
              <option key={m} value={m}>
                {m}
              </option>
            ))}
          </select>
        </Container>
      </SettingsFieldWrapper>
    );
  }
  if (name === AdvancedSettingsFields.maxAnswerLength) {
    return (
      <SettingsFieldWrapper>
        <Container top={24}>
          <Label>Max Answer Length</Label>
          <input id="maxAnswerLength" value={maxAnswerLength} onChange={onMaxAnswerLengthChangeEffect} />
        </Container>
      </SettingsFieldWrapper>
    );
  }
  if (name === AdvancedSettingsFields.version) {
    return (
      <SettingsFieldWrapper>
        <Container top={24}>
          <Label>Version</Label>
          <input id="version" value={version} onChange={onVersionChange} />
        </Container>
      </SettingsFieldWrapper>
    );
  }
  if (name === AdvancedSettingsFields.maxContextLength) {
    return (
      <SettingsFieldWrapper>
        <Container top={24}>
          <Label>Max Context Length</Label>
          <input id="maxContextLength" value={maxContextLength} onChange={onMaxContextLengthChange} />
        </Container>
      </SettingsFieldWrapper>
    );
  }
  if (name === AdvancedSettingsFields.maxContextChunks) {
    return (
      <SettingsFieldWrapper>
        <Container top={24}>
          <Label>Max Context Chunks</Label>
          <input id="maxContextChunks" value={maxContextChunks} onChange={onMaxContextChunksChange} />
        </Container>
      </SettingsFieldWrapper>
    );
  }
  if (name === AdvancedSettingsFields.seed) {
    return (
      <SettingsFieldWrapper>
        <Container top={24}>
          <Label>Seed</Label>
          <input id="seed" value={seed} onChange={onSeedChange} />
        </Container>
      </SettingsFieldWrapper>
    );
  }
  if (name === AdvancedSettingsFields.temperature) {
    return (
      <SettingsFieldWrapper>
        <Container top={24}>
          <Label>Temperature</Label>
          <input id="temperature" value={temperature} onChange={onTemperatureChange} />
        </Container>
      </SettingsFieldWrapper>
    );
  }
  if (name === AdvancedSettingsFields.top_p) {
    return (
      <SettingsFieldWrapper>
        <Container top={24}>
          <Label>Top P</Label>
          <input id="top_p" value={topP} onChange={onTopPChange} />
        </Container>
      </SettingsFieldWrapper>
    );
  }
  if (name === AdvancedSettingsFields.enableTextSearch) {
    return (
      <SettingsFieldWrapper>
        <Container top={36} row verticallyCenter>
          <input
            type="checkbox"
            id="enable-text-search"
            checked={enableTextSearch}
            onClick={setToggleEnableTextSearch}
            style={{ cursor: 'pointer', marginTop: 0, marginLeft: '0px', width: '14px' }}
          />
          <label
            htmlFor="enable-text-search"
            className="not-selectable"
            style={{ cursor: 'pointer', margin: 0, paddingLeft: '8px' }}
          >
            <Container bottom={3}>Enable Text Search</Container>
          </label>
        </Container>
      </SettingsFieldWrapper>
    );
  }
  if (name === AdvancedSettingsFields.enableMath) {
    return (
      <SettingsFieldWrapper>
        <Container top={36} row verticallyCenter>
          <input
            type="checkbox"
            id="enable-math"
            checked={enableMath}
            onClick={setToggleEnableMath}
            style={{ cursor: 'pointer', marginTop: 0, marginLeft: '0px', width: '14px' }}
          />
          <label
            htmlFor="enable-math"
            className="not-selectable"
            style={{ cursor: 'pointer', margin: 0, paddingLeft: '8px' }}
          >
            <Container bottom={3}>Enable Refinement</Container>
          </label>
        </Container>
      </SettingsFieldWrapper>
    );
  }
  if (name === AdvancedSettingsFields.includeInferredMetadata) {
    return (
      <SettingsFieldWrapper>
        <Container top={36} row verticallyCenter>
          <input
            type="checkbox"
            id="checkbox-include-inferred-metadata"
            checked={includeInferredMetadata}
            onClick={setToggleIncludeInferredMetadata}
            style={{ cursor: 'pointer', marginTop: 0, marginLeft: '0px', width: '14px' }}
          />
          <label
            htmlFor="checkbox-include-inferred-metadata"
            className="not-selectable"
            style={{ cursor: 'pointer', margin: 0, paddingLeft: '8px' }}
          >
            <Container bottom={3}>Include Inferred Metadata</Container>
          </label>
        </Container>
      </SettingsFieldWrapper>
    );
  }
  if (name === AdvancedSettingsFields.seeContext) {
    return (
      <SettingsFieldWrapper>
        <Container top={36} row verticallyCenter>
          <input
            type="checkbox"
            id="checkbox"
            checked={seeContext}
            onClick={onSeeContextChange}
            style={{ cursor: 'pointer', marginTop: 0, marginLeft: '0px', width: '14px' }}
          />
          <label
            htmlFor="checkbox"
            className="not-selectable"
            style={{ cursor: 'pointer', margin: 0, paddingLeft: '8px' }}
          >
            <Container bottom={3}>Show Sources</Container>
          </label>
        </Container>
      </SettingsFieldWrapper>
    );
  }
  if (name === AdvancedSettingsFields.enableJS) {
    return (
      <SettingsFieldWrapper>
        <Container top={36} row verticallyCenter>
          <input
            type="checkbox"
            id="enableJS"
            checked={enableJS}
            onClick={onEnableJSChange}
            style={{ cursor: 'pointer', marginTop: 0, marginLeft: '0px', width: '14px' }}
          />
          <label
            htmlFor="enableJS"
            className="not-selectable"
            style={{ cursor: 'pointer', margin: 0, paddingLeft: '8px' }}
          >
            <Container bottom={3}>Enable JS</Container>
          </label>
        </Container>
      </SettingsFieldWrapper>
    );
  }
  if (name === AdvancedSettingsFields.smoothingToggle) {
    return (
      <SettingsFieldWrapper>
        <Container top={36} row verticallyCenter>
          <input
            type="checkbox"
            id="smoothingcheckbox"
            checked={enableSmoothing}
            onClick={onEnableSmoothingChange}
            style={{ cursor: 'pointer', marginTop: 0, marginLeft: '0px', width: '14px' }}
          />
          <label
            htmlFor="smoothingcheckbox"
            className="not-selectable"
            style={{ cursor: 'pointer', margin: 0, paddingLeft: '8px' }}
          >
            <Container bottom={3}>Enable Smoothing</Container>
          </label>
        </Container>
      </SettingsFieldWrapper>
    );
  }

  return null;
};

export const AdvancedSettingsNode = (props) => {
  const { data, id, isConnectable } = props;
  const colorTheme = useColorTheme();

  const [settingSelectorValue, setSettingSelectorValue] = React.useState('');
  const [settingsFields, setSettingsFields] = React.useState([]);

  const onChangeSettingSelector = (e) => {
    setSettingSelectorValue(e.target.value);
  };

  const onAddSetting = () => {
    if (settingSelectorValue === '') return null;

    if (!settingsFields.includes(settingSelectorValue)) {
      setSettingsFields((prev) => [...prev, settingSelectorValue]);
      setSettingSelectorValue('');
    }
  };

  const onSettingValueChange = (name, value) => {
    data.actions.updateDataCb((prevData) => {
      const update = {
        [name]: value,
      };
      return {
        ...prevData,
        ...update,
      };
    });
  };
  const name = 'Advanced Settings';
  const settingsDropdownValues = AVAILABLE_SETTING_FIELDS.filter((name) => !settingsFields.includes(name));
  return (
    <NodeWrapper data-id={id} name={name} data={data}>
      <NodeHeader id={id} name={name} actions={data.actions} Left={8} Right={8} />
      <ContentWrapper>
        <Container bottom={16}>
          <Body5>Default values are the recommended values</Body5>
        </Container>
        <Container row verticallyCenter>
          <Select value={settingSelectorValue} onChange={onChangeSettingSelector}>
            <option value={''}>Please select...</option>
            {settingsDropdownValues.map((name) => (
              <option key={name} value={name}>
                {toAdvancedSettingDisplayName(name)}
              </option>
            ))}
          </Select>
          <Container left={8}>
            <FlatButton onClick={onAddSetting}>+ Add Setting</FlatButton>
          </Container>
        </Container>

        <Container top={24}>
          {settingsFields.map((fieldName) => (
            <SettingsField key={fieldName} name={fieldName} state={data} setValue={onSettingValueChange} />
          ))}
        </Container>
      </ContentWrapper>

      <InputSourceHandle {...props} handleActionId={NODE_HANDLE_IDS.AdvancedSettings} isConnectable={isConnectable} />

      <NodeDebugger id={id} />
    </NodeWrapper>
  );
};
